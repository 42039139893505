import react from "@heroicons/react";

// Import Context API
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import SystemContext from "../ContextAPI/SystemContext/SystemContext";

export default function SignOutPage() {
  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();
  useEffect(() => {
    systemContext.signOut();
    navigate("/login");
  }, []);
  return <div></div>;
}
