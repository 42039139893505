export async function SocketConnection(
  Host,
  user,
  io,
  setSocketId,
  setSystemErrorAlertValue,
  setServerConnectionStatus
) {
  return new Promise((resolve, reject) => {
    const token = user && user.token ? user.token : "";
    if (!token) {
      // reject(new Error("No token provided"));
      setSystemErrorAlertValue("Connection Error: Please login again!");
      setServerConnectionStatus(false);
      return;
    }

    const socketInstance = io(Host, {
      auth: { token },
    });

    socketInstance.on("connect", () => {
      console.log("Connected to the server.");
      setSocketId(socketInstance.id); // Update state with the socket ID
      setSystemErrorAlertValue("");
      setServerConnectionStatus(true);
      resolve(socketInstance); // Resolve the promise with the socket instance
    });

    socketInstance.on("connect_error", (err) => {
      setSystemErrorAlertValue("Connection Error: Please login again!");
      setServerConnectionStatus(false);
      console.error("Connection error:", err.message);
      reject(err); // Reject the promise on connection error
    });
    socketInstance.on("disconnect", (reason) => {
      setSystemErrorAlertValue("Disconnected");
      setServerConnectionStatus(false);
      console.log("Connection is broken:", reason); // Log that connection is broken
    });
  });
}
