// export async function ClaimLiveLead(socketInstance, id) {
//   return new Promise((resolve, reject) => {
//     // Emit an event with the specified id and wait for acknowledgement
//     socketInstance.emit("clientGetClaimLeads", { id: id }, (response) => {
//       // The server should send an acknowledgement response
//       // The response format depends on your server-side implementation
//       if (response && response.success) {
//         resolve("Claimed lead successfully.");
//       } else {
//         reject("Failed to claim lead.");
//       }
//     });
//   });
// }

export async function ClaimLiveLead(socketInstance, id) {
  return new Promise((resolve) => {
    // Function to handle the response
    const handleResponse = (response) => {
      // Remove the listener to avoid memory leaks
      socketInstance.off("serverReturnClaimLeadsResponse", handleResponse);

      // Check the response and resolve the promise with true or false
      if (response && response.status) {
        resolve({ status: true }); // Status is true, indicating success
        console.log("successfully assiged");
      } else {
        resolve({ status: false }); // Status is false, indicating failure
        console.log("unsuccessful assiged");
      }
    };

    // Set up a listener for the response event
    socketInstance.on("serverReturnClaimLeadsResponse", handleResponse);

    // Emit the event with the specified id
    socketInstance.emit("clientGetClaimLeads", { id: id });

    // Optionally, set a timeout to resolve the promise as false if no response is received within a certain time frame
    setTimeout(() => {
      socketInstance.off("serverReturnClaimLeadsResponse", handleResponse); // Clean up listener
      resolve(false); // Timeout reached, resolve as false
    }, 10000); // Adjust the timeout as necessary
  });
}
