import React, { useEffect, useState, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";

// Import ContextAPI
import { useContext } from "react";
import SystemContext from "../../ContextAPI/SystemContext/SystemContext";
import MainContext from "../../ContextAPI/MainContext/MainContext";
export default function AddCandidatebyLink() {
  const [loading, setLoading] = useState(false);
  const systemContext = useContext(SystemContext);
  const mainContext = useContext(MainContext);
  const [token, setToken] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState(true);

  // generateLinkAddCandidate
  useEffect(() => {
    async function main() {
      setLoading(true);
      // await systemContext.setdelay(5000);
      setLoading(false);
    }
    main();
  }, []);
  async function generateLink() {
    try {
      const val = await mainContext.generateLinkAddCandidate();
      console.log("Generate Link", val);
      if (val.linkToken) {
        setError(false);
        setToken(val.linkToken);
        setLink(
          `https://crm2.hirewynk.com/candidate-info?token=${val.linkToken}`
        );
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Failed to generate link", error);
      setError(true);
      // Handle the error accordingly, perhaps setting an error message in your state, etc.
    }
  }

  return (
    <>
      <>
        {/* Open the modal using document.getElementById('ID').showModal() method */}
        <button
          // className="btn"
          onClick={() => {
            document.getElementById("my_modal_1_add_by_link").showModal();
            generateLink();
          }}
        >
          Add by Link
        </button>
        <dialog id="my_modal_1_add_by_link" className="modal">
          <div className="modal-box">
            {loading ? loadingLink() : linkGenerated(link, error)}
          </div>
        </dialog>
      </>
    </>
  );
}
function loadingLink() {
  return (
    <>
      <>
        <h3 className="font-bold text-lg">Requesting Access</h3>
        <p className="py-4 pb-2 text-wrap">
          Please wait while we trying to assign this lead to your account.
        </p>
        <div className=" py-6 justify-center items-center h-[50px]">
          {/* <span className="loading loading-spinner loading-lg"></span> */}
          <div>
            <LinearProgress color="inherit" />
          </div>
        </div>
        <div className="modal-action">
          <form method="dialog">
            <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
              Cancel
            </button>
          </form>
        </div>
      </>
    </>
  );
}

function linkGenerated(link, error) {
  return (
    <>
      {!error && (
        <div>
          <h3 className="font-bold text-lg">Your Link is Generated!</h3>
          <p className="py-4">Your Link is {link}</p>
          <button
            onClick={() => {
              const textToCopy = link;
              navigator.clipboard.writeText(textToCopy);
              alert("Text copied to clipboard!");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
              />
            </svg>
          </button>
        </div>
      )}
      {error && (
        <div>
          <h3 className="font-bold text-lg">
            We failed to generate your link!
          </h3>
          <p className="py-4">
            Due to some technical issue we are failed to generate the link
          </p>
        </div>
      )}

      <div className="modal-action">
        <form method="dialog">
          <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
            Close
          </button>
        </form>
      </div>
    </>
  );
}
