export async function UploadFile(socketInstance, file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const fileData = e.target.result;
        socketInstance.emit("clientReturnLeadUploadData", {
          fileData,
          fileName: file.name,
          fileType: file.type,
        });

        // Resolve the promise with success status after emitting the data
        resolve({ success: true });
      };

      reader.onerror = function (error) {
        console.error("Error reading file:", error);
        // Reject the promise with error status and error message
        reject({ success: false, error: error.toString() });
      };

      reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer for binary data
    } catch (error) {
      console.error("Error uploading data:", error);
      // Reject the promise with error status and error message
      reject({ success: false, error: error.toString() });
    }
  });
}
