import React, { useEffect, useState, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment-timezone";

// Context API
import { useContext } from "react";
import SystemContext from "../../ContextAPI/SystemContext/SystemContext";

export default function CreateAccount() {
  const systemContext = useContext(SystemContext);
  const [submitState, setSubmitState] = useState(false);
  const [userDetail, setUserDetail] = useState({
    // name: "",
    // emailId: "",
    // uID: "",
    // employeeID: "",
    // password: "",
    // accessLevel: "",
  });
  const [showGif, setShowGif] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorEmptyFields, setErrorEmptyFields] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevLead) => ({
      ...prevLead,
      [name]: value, // Dynamically update the right property based on the element's name
    }));
  };
  async function handleSumit() {
    // setSubmitState(true);
    const { name, emailId, uID, employeeID, password, accessLevel } =
      userDetail;
    if (!name || !emailId || !uID || !employeeID || !password || !accessLevel) {
      setErrorEmptyFields(true);
      return; // Stop execution if any field is empty
    } else {
      setSubmitState(true);
    }
    try {
      setLoading(true);
      await systemContext.setdelay(1500);
      const response = await systemContext.addUserFunction(userDetail);
      console.log("Add candidate response: ", response);
      // Clear form fields after successful submission
      if (response.data.status === true) {
        setSuccess(true);
        setShowGif(true); // Set showGif to true if response is true
        setTimeout(() => {
          setShowGif(false); // Hide the GIF after some time (you can adjust the time as needed)
        }, 3000); // Set the timeout duration in milliseconds
      }
      setLoading(false);
      console.log("Accoutn Details: ", userDetail);
      setUserDetail({});
      // setSubmitState(false);
    } catch (error) {
      console.error("Error adding candidate: ", error);
      // Handle error, if any
    }
  }

  useEffect(() => {
    if (submitState) {
      handleDataUpdate();
    }
  }, [submitState]);
  //   useEffect(() => {
  //     console.log("Candidate Details: ", userDetail);
  //     // handleDataUpdate();
  //   }, [userDetail]);

  async function handleDataUpdate() {
    // Update userDetail with the new fields
    setUserDetail({
      ...userDetail,
    });
  }

  return (
    <>
      {/* <button
        // className="btn"
        onClick={() =>
          document.getElementById("my_modal_1_add_by_form").showModal()
        }
      >
        Add User
      </button> */}
      <button
        className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
        onClick={() =>
          document.getElementById("my_modal_4_add_new_user").showModal()
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span>Add New User</span>
      </button>
      <dialog id="my_modal_4_add_new_user" className="modal">
        <div className="" style={{ padding: "25px", marginTop: "120px" }}>
          <section
            className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800 lg:min-w-[1200px] p-8"
            style={{
              // maxHeight: "90vh", // Maximum height is 100% of the viewport height
              maxHeight: "400px",
              height: "auto",
              overflowY: "auto", // Will only show scrollbar when necessary
            }}
          >
            {/* <div className="divider py-4"></div> */}
            {!submitState && !loading && (
              <div>
                <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
                  New Account Details
                </h2>

                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      for="username"
                    >
                      Name
                    </label>
                    <input
                      name="name"
                      value={userDetail ? userDetail.name || "" : ""}
                      onChange={handleChange}
                      id="name"
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>

                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      for="text"
                    >
                      Email ID
                    </label>
                    <input
                      id="emailId"
                      name="emailId"
                      value={userDetail ? userDetail.emailId || "" : ""}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      for="uID"
                    >
                      UID
                    </label>
                    <input
                      id="uID"
                      name="uID"
                      value={userDetail ? userDetail.uID || "" : ""}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      for="employeeID"
                    >
                      Employee ID
                    </label>
                    <input
                      name="employeeID"
                      id="employeeID"
                      value={userDetail ? userDetail.employeeID || "" : ""}
                      //   value={userDetail.maritalStatus}
                      onChange={handleChange}
                      type="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      for="password"
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      id="password"
                      value={userDetail ? userDetail.password || "" : ""}
                      //   value={userDetail.homeTownCity}
                      onChange={handleChange}
                      type="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  <div>
                    <p
                      className="text-gray-700 dark:text-gray-200 pb-2"
                      htmlFor="accessLevel"
                    >
                      Access Level
                    </p>

                    <select
                      name="accessLevel" // Ensure this matches the state key
                      className="select select-bordered w-full max-w-xs"
                      value={userDetail ? userDetail.accessLevel || "" : ""}
                      //   value={userDetail.gender}
                      onChange={handleChange} // Use the generic handleChange for all form elements
                      aria-label="accessLevel"
                    >
                      <option disabled value="">
                        Access Options
                      </option>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                      <option value="superAdmin">Super Admin</option>
                    </select>
                  </div>
                </div>

                {errorEmptyFields && (
                  <div>
                    <label
                      className="text-gray-300 dark:text-gray-200 py-4"
                      for="password"
                      color="red"
                      style={{ color: "red" }}
                    >
                      Please enter all fields
                    </label>
                  </div>
                )}
                <div className="modal-action pt-4">
                  <button
                    className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                    onClick={() => {
                      handleSumit();
                    }}
                  >
                    Save
                  </button>
                  <form method="dialog">
                    <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            )}

            {success && submitState && !loading && (
              <>
                <div>{userSaved()}</div>
              </>
            )}

            {!success && submitState && !loading && (
              <>
                <div>{userNotSaved()}</div>
              </>
            )}
          </section>
          {loading && <>{locadingAnimation()}</>}
        </div>
      </dialog>
    </>
  );
}
function userSaved() {
  return (
    <>
      <div className=" flex flex-col items-center justify-center">
        <div className="flex justify-center">
          <img src="icons/celebrate.gif" alt="Correct" class="h-12" />
        </div>
        <h3 className="font-bold text-lg text-center">
          Congrat's! Request Accepted!
        </h3>

        <p className="py-4 pb-2 text-wrap">
          User has been created successfully!
        </p>
      </div>
      <div className="modal-action pt-4">
        <form method="dialog">
          <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
            Close
          </button>
        </form>
      </div>
    </>
  );
}
function userNotSaved() {
  return (
    <>
      <div className=" flex flex-col items-center justify-center">
        <div className="flex justify-center">
          <img src="icons/sad.gif" alt="failed" class="h-12" />
        </div>
        <h3 className="font-bold text-lg text-center pt-4">
          Oohh's! We failed to create a new user!
        </h3>

        <p className="py-4 pb-2 text-wrap">
          There is a technicaly issue which caused the failure of the account
          creation, please contact support.
        </p>
      </div>
      <div className="modal-action pt-4">
        <form method="dialog">
          <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
            Close
          </button>
        </form>
      </div>
    </>
  );
}
function locadingAnimation() {
  return (
    <>
      <div
        className=" flex flex-col items-center justify-center"
        style={{ background: "white", height: "200px" }}
      >
        <h3 className="font-bold text-lg text-center pt-4">
          Loading information....
        </h3>
        <h3 className="font-bold text-lg text-center pt-4">
          {/* <LinearProgress /> */}
          <CircularProgress />
        </h3>

        {/* <p className="py-4 pb-2 text-wrap">
          There is a technicaly issue which caused the failure of the account
          creation, please contact support.
        </p> */}
      </div>
      {/* <div className="modal-action pt-4">
        <form method="dialog">
          <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
            Close
          </button>
        </form>
      </div> */}
    </>
  );
}
