// State.js
import React, { useEffect, useState } from "react";
import MyContext from "./MainContext";

import { useContext } from "react";
import SystemContext from "../SystemContext/SystemContext";

// Import Functions
import { AddCandidateByLink } from "./Functions/AddCandidateByLink";
import { GenerateLinkForForm } from "./Functions/GenerateLink";

const MainStates = ({ children }) => {
  const Host = process.env.REACT_APP_API_URL || "http://localhost:5001";

  const systemContext = useContext(SystemContext);
  const [state, setState] = useState("Initial State");
  const [liveLeadsRerender, setLiveLeadsRerender] = useState(false);
  const [liveLeads, setLiveLeads] = useState([
    // {
    //   id: 12342134,
    //   name: "Suraj Sharma",
    //   preferred_city: "Kolkata",
    //   current_city: "Ranchi",
    //   current_state: "Jharkhand",
    //   ai_suggest: "Customer Support",
    //   experience: "2 Year",
    // },
    // {
    //   id: 12344534,
    //   name: "Neelava Bhattacharya",
    //   preferred_city: "Kolkata",
    //   current_city: "Bhubaneswar",
    //   current_state: "Odisha",
    //   ai_suggest: "Chat Support",
    //   experience: "2 Year",
    // },
  ]);
  const [myLeads, setMyLeads] = useState([]);
  const [myCandidateDetailOne, setMyCandidateDetailOne] = useState();

  // Function to update the state
  const updateState = (newValue) => {
    setState(newValue);
  };
  function requestLiveLeads() {
    // Check if the socket instance is connected and ready
    console.log("MainState: ", systemContext.socketInstance);
    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      console.log("getLiveLead request sent");
      // Send a message through the socket instance to request live leads
      systemContext.socketInstance.emit("clientGetLiveLeads", {});
    } else {
      console.error("Socket is not connected.");
    }
  }
  function requestMyClaimLeads() {
    // Check if the socket instance is connected and ready
    console.log("MainState: ", systemContext.socketInstance);
    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      console.log("getMyLead request sent");
      // Send a message through the socket instance to request live leads
      systemContext.socketInstance.emit("clientRequestMyClaimLeads", {});
    } else {
      console.error("Socket is not connected.");
    }
  }

  function requestUpdateMyClaimLeads(data) {
    // Check if the socket instance is connected and ready
    console.log("MainState: ", systemContext.socketInstance);
    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      console.log("updateMyClaimLeads request sent");

      // Define a one-time response handler
      const responseHandler = (message) => {
        if (message.status) {
          console.log("Lead Update was successful.");
          systemContext.setSystemSuccessAlertValue(
            "Lead Update was successful."
          );
        } else {
          console.error("Lead Update failed:", message.message);
          systemContext.setSystemWarningAlertValue(
            `Lead Update failed: ${message.message}`
          );
        }
        // Stop listening to this event to prevent memory leaks
        systemContext.socketInstance.off(
          "serverResponseUpdateMyClaimLeads",
          responseHandler
        );
      };

      // Listen for the server response specifically for this operation
      systemContext.socketInstance.on(
        "serverResponseUpdateMyClaimLeads",
        responseHandler
      );

      // Emit the request with data
      systemContext.socketInstance.emit(
        "clientRequestUpdateMyClaimLeads",
        data
      );
    } else {
      console.error("Socket is not connected.");
    }
  }

  function requestMyCandidateDetails(id) {
    // Check if the socket instance is connected and ready
    console.log("MainState: ", systemContext.socketInstance);
    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      console.log("getMyLead request sent");

      // Send a message through the socket instance to request live leads
      systemContext.socketInstance.emit("clientRequestMyCandidateDetails", {
        id: id,
      });
    } else {
      console.error("Socket is not connected.");
    }
  }

  function requestUpdateCandidateDetails(data) {
    // Check if the socket instance is connected and ready
    console.log("MainState: ", systemContext.socketInstance);
    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      console.log("Update Candidate Details request sent");

      // Define a response handler
      const responseHandler = (message) => {
        if (message.status) {
          console.log("Candidate details update was successful.");
          systemContext.setSystemSuccessAlertValue(
            "Candidate details update was successful."
          );
        } else {
          console.error("Candidate details update failed:", message.message);
          systemContext.setSystemWarningAlertValue(
            `Candidate details update failed: ${message.message}`
          );
        }
        // Stop listening to avoid memory leaks
        systemContext.socketInstance.off(
          "serverResponseUpdateCandidateDetails",
          responseHandler
        );
      };

      // Listen for the server response
      systemContext.socketInstance.on(
        "serverResponseUpdateCandidateDetails",
        responseHandler
      );

      // Send a message through the socket instance to request live leads
      systemContext.socketInstance.emit(
        "clientRequestUpdateCandidateDetails",
        data
      );
    } else {
      console.error("Socket is not connected.");
    }
  }
  async function addCandidateByLinkFunction(data, token) {
    // Append the token to the data object
    const modifiedData = { ...data, urlToken: token };

    // Assuming systemContext.Host contains the host URL

    const response = await AddCandidateByLink(Host, modifiedData);

    return response;
  }
  async function generateLinkAddCandidate() {
    const storedData = localStorage.getItem("user");

    const user = storedData ? JSON.parse(storedData) : null;
    console.log("user token", user.token);
    const response = await GenerateLinkForForm(Host, user.token);
    return response.data;
  }
  // ====================================

  useEffect(() => {
    requestLiveLeads();
    requestMyClaimLeads();
  }, [systemContext.socketInstance]);

  useEffect(() => {
    // Define a function to handle incoming messages
    // const handleNewLiveLeads = (message) => {
    //   console.log("Received message on serverReturnLiveLeads:", message);
    //   setLiveLeads(message);
    // };

    const handleNewLiveLeads = (message) => {
      console.log("Received message on serverReturnLiveLeads:", message);

      // Retrieve and parse filter settings from localStorage
      const data = localStorage.getItem("liveLeadsFilter");
      const filters = JSON.parse(data) || {};

      // Extract filter criteria
      const selectedLocations = filters.selectedLocations || [];
      const preferredLocations = filters.preferredLocation || [];
      const currentLocations = filters.currentLocation || [];

      console.log("Selected Location for Filter: ", selectedLocations);

      // Filter logic for selected, preferred, and current locations
      const filteredLeads = message.filter((lead) => {
        const locationMatch =
          selectedLocations.length === 0 ||
          selectedLocations.includes(lead.currentLocation) ||
          lead.preferredLocations.some((loc) =>
            selectedLocations.includes(loc)
          );

        const preferredLocationMatch =
          preferredLocations.length === 0 ||
          lead.preferredLocations.some((loc) =>
            preferredLocations.includes(loc)
          );

        const currentLocationMatch =
          currentLocations.length === 0 ||
          currentLocations.includes(lead.currentLocation);

        return locationMatch && preferredLocationMatch && currentLocationMatch;
      });

      // Update state with filtered leads
      setLiveLeads(filteredLeads);
      console.log("Filtered Live Leads: ", filteredLeads);
    };

    // const handleNewLiveLeads = (message) => {
    //   console.log("Received message on serverReturnLiveLeads:", message);

    //   // Retrieve and parse filter settings from localStorage
    //   const data = localStorage.getItem("liveLeadsFilter");
    //   const filters = JSON.parse(data) || {};

    //   // Extract filter criteria
    //   const selectedLocations = filters.selectedLocations || [];
    //   console.log("Selected Location for Filter: ", selectedLocations);
    //   // const startExperience = filters.startExperience || { year: "", month: "" };
    //   // const endExperience = filters.endExperience || { year: "", month: "" };

    //   // Convert experience filters from string to total months for easier comparison
    //   // const startExperienceMonths = parseInt(startExperience.year) * 12 + parseInt(startExperience.month);
    //   // const endExperienceMonths = parseInt(endExperience.year) * 12 + parseInt(endExperience.month);

    //   // Filter leads based on location
    //   const filteredLeads = message.filter((lead) => {
    //     const locationMatch =
    //       selectedLocations.includes(lead.currentLocation) ||
    //       lead.preferredLocations.some((loc) =>
    //         selectedLocations.includes(loc)
    //       );
    //     // const leadExperienceMonths = parseExperience(lead.totalExperience);
    //     // const experienceMatch = (leadExperienceMonths >= startExperienceMonths) && (leadExperienceMonths <= endExperienceMonths);

    //     return locationMatch; // && experienceMatch;
    //   });

    //   // Update state with filtered leads
    //   setLiveLeads(filteredLeads);
    //   console.log("Live Leads: ", filteredLeads);
    // };

    // Helper function to parse "X Year(s) Y Month(s)" string into total months
    // function parseExperience(experience) {
    //   const parts = experience.split(' ');
    //   const years = parseInt(parts[0]);
    //   const months = parseInt(parts[2]);
    //   return years * 12 + months;
    // }

    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      // Subscribe to the 'serverLiveLeads' topic
      systemContext.socketInstance.on(
        "serverReturnLiveLeads",
        handleNewLiveLeads
      );

      // Return a cleanup function that will be called on component unmount
      return () => {
        // Unsubscribe from the 'serverLiveLeads' topic to prevent memory leaks
        // systemContext.socketInstance.off("serverLiveLeads", handleNewLiveLeads);
      };
    }
  }, [systemContext.socketInstance, liveLeadsRerender]); // Dependency array ensures effect runs only when socketInstance changes
  useEffect(() => {
    // Define a function to handle incoming messages
    const handleMyClaimLeads = (message) => {
      console.log("Received message on serverResponseMyClaimLeads:", message);
      setMyLeads(message);
    };

    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      // Subscribe to the 'serverLiveLeads' topic
      systemContext.socketInstance.on(
        "serverResponseMyClaimLeads",
        handleMyClaimLeads
      );

      // Return a cleanup function that will be called on component unmount
      return () => {
        // Unsubscribe from the 'serverLiveLeads' topic to prevent memory leaks
        // systemContext.socketInstance.off("serverLiveLeads", handleNewLiveLeads);
      };
    }
  }, [systemContext.socketInstance]); // Dependency array ensures effect runs only when socketInstance changes

  useEffect(() => {
    // Define a function to handle incoming messages
    const handleMyCandidateDetails = (message) => {
      console.log(
        "Received message on serverResponseMyCandidateDetails:",
        message
      );
      setMyCandidateDetailOne(message);
    };

    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      // Subscribe to the 'serverLiveLeads' topic
      systemContext.socketInstance.on(
        "serverResponseMyCandidateDetails",
        handleMyCandidateDetails
      );

      // Return a cleanup function that will be called on component unmount
      return () => {
        // Unsubscribe from the 'serverLiveLeads' topic to prevent memory leaks
        // systemContext.socketInstance.off("serverLiveLeads", handleNewLiveLeads);
      };
    }
  }, [systemContext.socketInstance]); // Dependency array ensures effect runs only when socketInstance changes

  useEffect(() => {
    // Define a function to handle incoming messages
    const useraddFunction = (message) => {
      console.log("Received message on useradd:", message);
      setMyCandidateDetailOne(message);
    };

    if (
      systemContext.socketInstance &&
      systemContext.socketInstance.connected
    ) {
      // Subscribe to the 'serverLiveLeads' topic
      systemContext.socketInstance.on("useradd", useraddFunction);

      // Return a cleanup function that will be called on component unmount
      return () => {
        // Unsubscribe from the 'serverLiveLeads' topic to prevent memory leaks
        // systemContext.socketInstance.off("serverLiveLeads", handleNewLiveLeads);
      };
    }
  }, [systemContext.socketInstance]); // Dependency array ensures effect runs only when socketInstance changes
  return (
    // The Provider component makes the state available to any child component that calls useContext(MyContext).
    <MyContext.Provider
      value={{
        state,
        updateState,
        liveLeads,
        setLiveLeads,
        myLeads,
        setMyLeads,
        myCandidateDetailOne,
        setMyCandidateDetailOne,
        liveLeadsRerender,
        setLiveLeadsRerender,
        //Functions

        requestLiveLeads,
        requestMyClaimLeads,
        requestUpdateMyClaimLeads,
        requestMyCandidateDetails,
        requestUpdateCandidateDetails,

        addCandidateByLinkFunction,
        generateLinkAddCandidate,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MainStates;
