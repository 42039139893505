// State.js
import React, { useEffect, useState } from "react";
import SystemContext from "./SystemContext";
import io from "socket.io-client";
import moment from "moment-timezone";

// Import Functions
import { GetAuth } from "./Functions/GetAuth";
import { SocketConnection } from "./Functions/SocketConnection";
import { UploadFile } from "./Functions/UploadFile";
import { ClaimLiveLead } from "./Functions/ClaimLiveLead";
import { AddCandidate } from "./Functions/AddCandidate";
import { AddUserAccount } from "./Functions/AddUserAccount";

const SystemStates = ({ children }) => {
  const Host = process.env.REACT_APP_API_URL || "http://localhost:5001";
  const [time, setTime] = useState(moment().tz("Asia/Kolkata").toDate());

  const [state, setState] = useState("Initial State");
  const [socketId, setSocketId] = useState("");
  const [socketInstance, setSocketInstance] = useState(null); // State to hold the socket instance

  const [systemErrorAlertValue, setSystemErrorAlertValue] = useState("");
  const [systemSuccessAlertValue, setSystemSuccessAlertValue] = useState("");
  const [systemWarningAlertValue, setSystemWarningAlertValue] = useState("");
  const [systemInfoAlertValue, setSystemInfoAlertValue] = useState("");
  const [serverConnectionStatus, setServerConnectionStatus] = useState(false);

  const [user, setUser] = useState({
    success: null,
    message: "",
    token: "",
    user: {
      _id: "",
      email: "",
      employeeID: "",
      name: "",
      accessLevel: null,
    },
  });
  const updateState = (newValue) => {
    setState(newValue);
  };

  const updateUserState = (data) => {
    if (data) {
      setUser((prevState) => {
        const newState = { ...prevState };
        Object.keys(data).forEach((key) => {
          if (newState.hasOwnProperty(key)) {
            newState[key] = data[key];
          } else if (newState.user && newState.user.hasOwnProperty(key)) {
            newState.user[key] = data[key];
          }
        });
        console.log("New state inside setUser:", newState);
        return newState;
      });
    }
  };
  const uploadLeadData = async (file) => {
    if (file && socketInstance && socketInstance.connected) {
      try {
        const result = await UploadFile(socketInstance, file);
        console.log(`Upload status: ${result.success ? "Success" : "Failed"}`);
      } catch (error) {
        console.error(`Upload failed with error: ${error.error}`);
      }
    }
  };

  useEffect(() => {
    if (user.token) {
      console.log("Current user state:", user);
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  const authenticated = async (loginID, pwd) => {
    // console.log("System context is called");
    const res = await GetAuth(Host, loginID, pwd);
    // updateUserState(res.data);
    setUser(res.data);
    localStorage.setItem("user", JSON.stringify(res.data));
    localStorage.setItem("tokenCreationTime", new Date().toISOString());
    console.log("System context is called ", res.data);

    return res;
  };
  // AddUserAccount
  const addUserFunction = async (userDetail) => {
    // console.log("System context is called");
    const res = await AddUserAccount(Host, userDetail);

    return res;
  };
  const signOut = () => {
    setUser({});
    localStorage.removeItem("user");
    localStorage.removeItem("tokenCreationTime");
  };
  const claimLead = async (id) => {
    // return null;
    console.log("claimLead is called", id);

    if (id && socketInstance && socketInstance.connected) {
      try {
        const result = await ClaimLiveLead(socketInstance, id);
        console.log(`Upload status: ${result.status ? "Success" : "Failed"}`);
        return result;
      } catch (error) {
        console.error(`Upload failed with error: ${error.error}`);
      }
    }
  };
  // const addCandidateFunction = async (data) => {
  //   try {
  //     const response = await AddCandidate(Host, data);
  //     console.log("Response: ", response);
  //     return response;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     throw error;
  //   }
  // };
  const addCandidateFunction = async (data) => {
    try {
      const res = await AddCandidate(Host, data);
      const response = res.data;
      console.log("Response: ", response);

      // Assuming the response object has the structure {newCandidateStatus, leadAdded, newLeadStatus, name, leadId, candidateProfileId}
      if (response.leadAdded) {
        if (response.newCandidateStatus) {
          // Success: New candidate and lead added
          setSystemSuccessAlertValue(
            `Lead added successfully. \nCandidate: ${response.name}, \nCandidate Profile ID: ${response.candidateProfileId}, \nLead ID: ${response.leadId}`
          );
        } else {
          // Warning: Lead added but candidate already present
          setSystemSuccessAlertValue(
            `Lead added successfully. Lead ID: ${response.leadId}`
          );
          setSystemWarningAlertValue(
            `Candidate already present. Candidate Profile ID: ${response.candidateProfileId}`
          );
        }
      } else {
        // Warning: Candidate and lead already present
        setSystemErrorAlertValue(
          `Lead and Candidate Profile already present. \nCandidate Profile ID: ${response.candidateProfileId}, \nLead ID: ${response.leadId}`
        );
      }

      return response;
    } catch (error) {
      console.error("Error:", error);
      setSystemErrorAlertValue(`An error occurred: ${error.message}`);
      throw error;
    }
  };

  useEffect(() => {
    // const run = async () => {
    //   const socketInstanceTemp = await SocketConnection(
    //     Host,
    //     user,
    //     io,
    //     setSocketId,
    //     setSystemErrorAlertValue,
    //     setServerConnectionStatus
    //   );
    //   setSocketInstance(socketInstanceTemp);
    //   console.log("Connection: ", socketInstanceTemp);
    // };
    // run();

    SocketConnection(
      Host,
      user,
      io,
      setSocketId,
      setSystemErrorAlertValue,
      setServerConnectionStatus
    )
      .then((socketInstanceTemp) => {
        setSocketInstance(socketInstanceTemp);
        console.log("Connection: ", socketInstanceTemp);
      })
      .catch((error) => {
        console.error("Error establishing socket connection: ", error);
      });
  }, [user]); // Dependency on 'user' ensures this effect runs on user change and page load

  function setdelay(ms) {
    // Return a new promise that resolves after 'ms' milliseconds
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    console.log("Mapped User: ", user);
    const value = user.hasOwnProperty("token") ? user.token : "";
    console.log("Value : ", value);
  }, [user]);

  // Load the default values from the localstorage
  // useEffect(() => {
  //   const savedValue = JSON.parse(localStorage.getItem("user") || "{}");
  //   if (savedValue) {
  //     console.log("Saved Value: ", savedValue);
  //     setUser(savedValue);
  //   }
  // }, []);
  useEffect(() => {
    const savedValue = JSON.parse(localStorage.getItem("user") || "{}");
    // Check if savedValue has any keys, indicating it's not an empty object
    if (Object.keys(savedValue).length > 0) {
      console.log("Saved Value: ", savedValue);
      setUser(savedValue);
    }
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(moment().tz("Asia/Kolkata").toDate());
    }, 1000);

    return () => clearInterval(timerId); // Clean up the interval on component unmount
  }, []);

  return (
    // The Provider component makes the state available to any child component that calls useContext(MyContext).
    <SystemContext.Provider
      value={{
        user,
        state,
        updateState,
        authenticated,
        setdelay,
        systemErrorAlertValue,
        setSystemErrorAlertValue,
        systemSuccessAlertValue,
        setSystemSuccessAlertValue,
        systemWarningAlertValue,
        setSystemWarningAlertValue,
        systemInfoAlertValue,
        setSystemInfoAlertValue,
        serverConnectionStatus,
        setServerConnectionStatus,
        socketInstance,

        //
        // Functions
        time,
        uploadLeadData,
        claimLead,
        signOut,
        addCandidateFunction,
        addUserFunction,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};

export default SystemStates;
