import React, { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";

// Import Context
import { useContext } from "react";
import SystemContext from "../../ContextAPI/SystemContext/SystemContext";

export default function ClaimLead({ props }) {
  const systemContext = useContext(SystemContext);
  // Ensure props is an object to avoid destructuring errors
  const validProps = props || {};

  // Provide default values during destructuring to avoid undefined errors
  // const {
  //   name = "",
  //   location = "",
  //   current_access = 0,
  //   max_access = 0,
  // } = validProps;

  // Initialize state with destructured props or default values
  const [data, setData] = useState({
    id: 0,
    name: "",
    currentLocation: "",
    current_access: "",
    max_access: "",
  });
  const [claimError, setClaimError] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [process, setProcess] = useState(false);

  useEffect(() => {
    setData({
      id: props.id,
      name: props.name,
      currentLocation: props.currentLocation,
      current_access: "",
      max_access: "",
    });
    // console.log("Got from: ", props);
  }, [props]);

  const handleOnClaim = async (id) => {
    console.log("click");
    setProcess(true);
    await systemContext.setdelay(1500);
    const res = await systemContext.claimLead(id);
    setProcess(false);
    if (res.status) {
      setClaimSuccess(true);
      setClaimError(false);
    } else {
      setClaimSuccess(false);
      setClaimError(true);
    }
  };
  return (
    <>
      <>
        <button
          className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
          onClick={() =>
            document.getElementById(`modal_claim_${data.id}`).showModal()
          }
        >
          Claim
        </button>
        <dialog id={`modal_claim_${data.id}`} className="modal">
          <div className="modal-box">
            {!process && !claimSuccess && !claimError && (
              <>{info(data, handleOnClaim)}</>
            )}
            {process && <> {requestingAccess()}</>}
            {!process && claimSuccess && <> {requestAccepted(data)}</>}
            {!process && claimError && <> {requestRejected(data)}</>}
          </div>
        </dialog>
      </>
    </>
  );
}
function info(data, handleOnClaim) {
  return (
    <>
      <>
        <h3 className="font-bold text-lg">Request To Access Lead</h3>
        <p className="py-2 text-wrap">
          Name : <span style={{ fontWeight: "500" }}>{data.name}</span>
          <br />
          Location{" "}
          <span style={{ fontWeight: "500" }}>{data.currentLocation}</span>
        </p>
        <p className="py-2 text-wrap">
          Access to lead will be reserved to you for{" "}
          <span style={{ fontWeight: "500" }}>12 hours/days</span> . Ensure to
          complete your work within this period.
        </p>

        <p className="py-2 p1-1 text-wrap">
          Access Taken{" "}
          <div className="badge bg-blue-500 text-white">
            {data.current_access}/{data.max_access}
          </div>
        </p>
        <p className="my-4"></p>
        <p className="py-4 pb-2 text-wrap">
          Confirm your claim by clicking "Request" or cancel to review other
          data.
        </p>
        <p className="text-wrap">
          Please be aware admin will me notified for your action
        </p>
        <div className="modal-action">
          {/* if there is a button in form, it will close the modal */}
          {/* <button className="btn mx-2 bg-blue-500"> */}
          <button
            className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
            onClick={() => {
              handleOnClaim(data.id);
            }}
          >
            Request
          </button>
          <form method="dialog">
            {/* <button className="btn"> */}
            <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
              Cancel
            </button>
          </form>
        </div>
      </>
    </>
  );
}
function requestingAccess() {
  return (
    <>
      <>
        <h3 className="font-bold text-lg">Requesting Access</h3>
        <p className="py-4 pb-2 text-wrap">
          Please wait while we trying to assign this lead to your account.
        </p>
        <div className=" py-6 justify-center items-center h-[50px]">
          {/* <span className="loading loading-spinner loading-lg"></span> */}
          <div>
            <LinearProgress color="inherit" />
          </div>
        </div>
        <div className="modal-action">
          <form method="dialog">
            <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
              Cancel
            </button>
          </form>
        </div>
      </>
    </>
  );
}
function requestAccepted(data) {
  return (
    <>
      <>
        <div className="flex justify-center w-full">
          <img src="icons/celebrate.gif" alt="Celebrate" />
        </div>

        <h3 className="font-bold text-lg text-center">
          Congrat's! Request Accepted!
        </h3>
        <p className="py-2 text-wrap">
          Name : <span style={{ fontWeight: "500" }}>{data.name}</span>
          <br />
          Location{" "}
          <span style={{ fontWeight: "500" }}>{data.currentLocation}</span>
        </p>
        <p className="py-2 text-wrap">
          Access to lead will be reserved to you for{" "}
          <span style={{ fontWeight: "500" }}>12 hours/days</span> . Ensure to
          complete your work within this period.
        </p>
        <p className="py-2 p1-1 text-wrap">
          Access Taken{" "}
          <div className="badge bg-blue-500 text-white">
            {data.current_access}/{data.max_access}
          </div>
        </p>
        <p className="my-4"></p>
        <div className="modal-action">
          <form method="dialog">
            <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
              Close
            </button>
          </form>
        </div>
      </>
    </>
  );
}
function requestRejected(data) {
  return (
    <>
      <>
        <div className="flex justify-center w-full">
          <img src="icons/sad.gif" alt="Celebrate" />
        </div>

        <h3 className="font-bold text-lg text-center">
          Sorry! Request Rejected!
        </h3>

        <p className="py-3 text-wrap font-bold text-stone-500">
          Someone beat you to get the lead. Remember, the early bird catches the
          worm, so make sure be fast next time!
        </p>

        <div className="py-2 p1-1 text-wrap">
          Access Taken{" "}
          <div className="badge bg-blue-500 text-white">
            {data.current_access}/{data.max_access}
          </div>
        </div>
        <p className="my-4"></p>
        <div className="modal-action">
          <form method="dialog">
            <button className=" items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-neutral-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-neutral-300 dark:hover:bg-blue-500 dark:bg-blue-600">
              Close
            </button>
          </form>
        </div>
      </>
    </>
  );
}
