import React, { useEffect, useState, useCallback } from "react";
import { ReactTags } from "react-tag-autocomplete";

// Import ContextAPI
import { useContext } from "react";
import MainContext from "../../ContextAPI/MainContext/MainContext";

export default function ViewLead({ lead }) {
  const mainContext = useContext(MainContext);
  const [mylead, setMyLead] = useState(lead);
  const [candidateDetail, setCandidateDetail] = useState();
  useEffect(() => {
    setMyLead(lead);
  }, [lead]);
  // requestMyCandidateDetails
  // useEffect(() => {
  //   mainContext.requestMyCandidateDetails(mylead.candidateProfileId);

  //   console.log("My Candidate Details: ", mylead);
  // }, [lead]);
  useEffect(() => {
    console.log("My Candidate Details: ", mainContext.myCandidateDetailOne);
    setCandidateDetail(mainContext.myCandidateDetailOne);
  }, [mainContext.myCandidateDetailOne]);

  async function getMyCandidateDetails(data) {
    await mainContext.requestMyCandidateDetails(data);
    console.log("My Candidate Details: ", mylead);
  }
  async function handleSumit() {
    await mainContext.requestUpdateMyClaimLeads(mylead);
    await mainContext.requestUpdateCandidateDetails(candidateDetail);
    console.log("Submit: ", mylead);
  }
  return (
    <>
      <>
        {/* Open the modal using document.getElementById('ID').showModal() method */}
        <button
          className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
          onClick={() => {
            getMyCandidateDetails(mylead.candidateProfileId);
            document
              .getElementById(`modal_view_claim_lead_${lead.id}`)
              .showModal();
          }}
        >
          View
        </button>
        <dialog id={`modal_view_claim_lead_${lead.id}`} className="modal ">
          {/* <div className="modal-box ">
            <h3 className="font-bold text-lg">Hello!</h3>
            <p className="py-4">
              Press ESC key or click the button below to close
            </p>
          </div> */}
          {LeadForm(
            mylead,
            setMyLead,
            candidateDetail,
            setCandidateDetail,
            handleSumit
          )}
        </dialog>
      </>
    </>
  );
}

function LeadForm(
  mylead,
  setMyLead,
  candidateDetail,
  setCandidateDetail,
  handleSumit
) {
  // const [recruiterFeedbackOptions, setRecruiterFeedbackOptions] = useState([
  //   "Job Stability Concerns",
  //   "Work Schedule Preferences",
  //   "Incompatibility with International Assignments",
  //   "Salary Expectations Not Met",
  //   "Communication Skills Development Needed",
  //   "Resignation Without Notice",
  //   "Preference Against BPO Sector Engagement",
  //   "Seeking Work-from-Home Opportunities",
  //   "Age Beyond Company’s Hiring Policy",
  //   "Availability Limited to Night Shifts",
  //   "Availability Limited to Day Shifts",
  //   "Strong Technical Skillset",
  //   "Availability for Immediate Onboarding",
  //   "Other Considerations",
  // ]);
  const [recruiterFeedbackOptions, setRecruiterFeedbackOptions] = useState({
    recruiterSelected: [
      "Strong Technical Skillset", // Highly valuable for technical roles
      "Availability for Immediate Onboarding", // Ideal for urgent hiring needs
      "Excellent Communication Skills", // Crucial for client-facing and team collaboration roles
      "Adaptability to Work Schedule", // Flexibility in work hours or shifts
      "Positive Work Ethic and Professionalism", // Indicative of a reliable and committed candidate
      "Cultural Fit", // Aligns well with the company's values and team dynamics
      "Leadership Qualities", // Potential for managerial or leadership roles
      "Proven Track Record of Success", // Demonstrated success in previous roles
      "Willingness for International Assignments", // Openness to global opportunities
      "Innovative Thinking and Creativity", // Ability to contribute fresh ideas and solve problems
      "Strong Analytical and Problem-Solving Skills", // Essential for roles requiring critical thinking
      // Add more positive selection reasons as needed
    ],
    recruiterRejected: [
      "Concerns Over Job Stability", // History of frequent job changes
      "Mismatched Work Schedule Preferences", // Preferences do not align with job requirements
      "Salary Expectations Above Budget", // Candidate's salary requirements exceed the allocated budget
      "Need for Further Development in Communication Skills", // Communication skills do not meet the role's requirements
      "Resigned from Last Position Without Proper Notice", // Raises concerns about professionalism and reliability
      "Not Interested in Sector-Specific Roles", // Lack of interest in the specific sector the company operates in
      "Preference for Remote Work Only", // Incompatibility with roles requiring physical presence
      "Exceeds Age Guidelines for New Hires", // Age-related criteria set by the company
      "Availability Confined to Specific Shifts Only", // Inability to accommodate required work schedules
      "Concerns About Adaptability and Cultural Fit", // Potential challenges in integrating with team or company culture
      "Lack of Technical Skills Required for the Role", // Essential technical skills are missing
      "Other Reasons", // Miscellaneous reasons that do not fit the above categories
      // Add more reasons for rejection as needed
    ],
    rnr: ["Phone Number Incorrect", "Email ID Incorrect"],
  });

  const [clientFeedbackOptions, setClientFeedbackOptions] = useState("");
  const [clientFeedbackList, setClientFeedbackList] = useState({
    clientSelected: [
      "Qualifications",
      "Experience",
      "Cultural Fit",
      "Interview Performance",
      "Skills Match",
    ],
    clientRejected: [
      "Lack of Experience",
      "Inadequate Qualifications",
      "Poor Interview Performance",
      "Cultural Mismatch",
      "Other Candidate More Suitable",
    ],
  });
  const [candidateStatusList, setCandidateStatusList] = useState({
    applicationPhases: [
      "Application Received",
      "Under Review",
      "Interview Scheduled",
      "Interview Completed",
    ],
    offerPhases: ["Offer Extended", "Offer Accepted", "Offer Declined"],
    preJoiningPhases: [
      "Background Check Initiated",
      "Background Check Cleared",
      "Background Check Issues",
    ],
    joiningPhases: [
      "Joining Date Confirmed",
      "Joined",
      "Delayed Joining",
      "Offer Drop",
    ],
  });

  const [location, setLocation] = useState([
    // Metro Cities
    "Mumbai",
    "Delhi",
    "Kolkata",
    "Chennai",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    // State Capitals
    "Bhopal",
    "Patna",
    "Panaji",
    "Gandhinagar",
    "Chandigarh",
    "Ranchi",
    "Thiruvananthapuram",
    "Bhubaneswar",
    "Dehradun",
    "Dispur",
    "Shimla",
    "Srinagar",
    "Jaipur",
    "Gangtok",
    "Itanagar",
    "Raipur",
    "Shillong",
    "Aizawl",
    "Kohima",
    "Imphal",
    "Agartala",
    "Lucknow",
    // Union Territory Capitals
    "Port Blair",
    "Daman",
    "Diu",
    "Silvassa",
    "Puducherry",
    "Leh",
    // Popular Cities
    "Varanasi",
    "Agra",
    "Jaipur",
    "Udaipur",
    "Amritsar",
    "Kochi",
    "Mysore",
    "Visakhapatnam",
    "Surat",
    "Indore",
    "Nagpur",
    "Lucknow",
    "Coimbatore",
    "Madurai",
    "Ludhiana",
    "Kanpur",
    "Nashik",
    "Vadodara",
    "Rajkot",
    "Meerut",
    "Faridabad",
    "Ghaziabad",
    "Rajahmundry",
    "Jabalpur",
    "Srinagar",
    "Thane",
    "Aurangabad",
    "Dhanbad",
    "Amravati",
    "Kolhapur",
    "Ajmer",
    "Tiruchirappalli",
    "Jodhpur",
    "Gwalior",
    "Noida",
    "Jalandhar",
    "Gurgaon",
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMyLead((prevLead) => ({
      ...prevLead,
      [name]: value, // Dynamically update the right property based on the element's name
    }));
  };
  const handleChangeArray = (e) => {
    const { name, value } = e.target;
    setMyLead((prevLead) => ({
      ...prevLead,
      [name]: [value], // Dynamically update the right property based on the element's name
    }));
  };
  // useEffect(() => {
  //   console.log("My Lead: ", mylead);
  // }, [mylead]);f

  // useEffect(() => {
  //   console.log("Candidate Detail: ", candidateDetail);
  // }, [candidateDetail]);
  useEffect(() => {
    // Assuming candidateDetail contains all the necessary keys
    // and setMylead is the function to update mylead
    if (candidateDetail) {
      setMyLead((prev) => ({
        ...prev, // Preserve existing mylead properties
        // Update mylead with candidateDetail's values for specified keys
        name: candidateDetail.name || "",
        emailId: candidateDetail.emailId || "",
        phoneNumber: candidateDetail.phoneNumber || null,
        currentLocation: candidateDetail.currentLocation || "",
        preferredLocations: candidateDetail.preferredLocations || [],
        totalExperience: candidateDetail.totalExperience || "",
        noticePeriodAvailabilityToJoin:
          candidateDetail.noticePeriodAvailabilityToJoin || "",
        gender: candidateDetail.gender || "",
        maritalStatus: candidateDetail.maritalStatus || "",
        homeTownCity: candidateDetail.homeTownCity || "",
        permanentAddress: candidateDetail.permanentAddress || "",
      }));
    }
  }, [candidateDetail]);
  useEffect(() => {
    console.log("My Lead: ", mylead);
  }, [mylead]);

  return (
    <>
      <>
        <div>
          <section
            className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800 lg:min-w-[1200px] p-8"
            style={{
              maxHeight: "90vh", // Maximum height is 100% of the viewport height
              overflowY: "auto", // Will only show scrollbar when necessary
            }}
          >
            {/* <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
              Lead Details
            </h2>
            <h3 className="text-lg font-semibold text-gray-500 capitalize dark:text-white my-2">
              {lead.name}, {lead.currentLocation}
            </h3> */}
            <div className="flex justify-between items-end">
              <div>
                <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1">
                  <div className="badge badge-lg  badge-outline">
                    Lead ID: {mylead.id}
                  </div>
                </h2>
                <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1">
                  <div className="badge badge-lg  badge-outline">
                    Candidate Profile ID: {mylead.candidateProfileId}
                  </div>
                </h2>
                <div className="badge badge-lg badge-ghost">
                  {mylead.name}, {mylead.currentLocation}
                </div>
                <div className="badge badge-lg badge-ghost">
                  Contact : {mylead.phoneNumber}
                </div>
              </div>

              <div className="modal-action ">
                <form method="dialog">
                  <button
                    className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                    onClick={() => {
                      handleSumit();
                    }}
                  >
                    Save
                  </button>
                  <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
            <div className="divider py-4"></div>
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
              Recruiter
            </h2>
            <form>
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Application Status
                  </p>

                  {/* <select
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Application Status
                    </option>
                    <option>RNR</option>
                    <option>Short Listed</option>
                    <option>Not Short Listed</option>
                  </select> */}
                  <select
                    name="applicantStatus" // Ensure this matches the state key
                    className="select select-bordered w-full max-w-xs"
                    value={mylead.applicantStatus}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Application Status
                    </option>
                    <option value="rnr">RNR</option>
                    <option value="recruiterSelected">Selected</option>
                    <option value="recruiterRejected">Rejected</option>
                  </select>
                </div>
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Recruiter Feedback
                  </p>

                  <select
                    name="recruitFeedback"
                    className="select select-bordered w-full max-w-xs"
                    value={mylead.recruitFeedback}
                    defaultValue=""
                    onChange={handleChangeArray} // Use the generic handleChange for all form elements
                    aria-label="Recruiter Feedback"
                  >
                    <option disabled value="">
                      Recruiter Feedback
                    </option>
                    {/* {recruiterFeedbackOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))} */}
                    {mylead.applicantStatus &&
                      recruiterFeedbackOptions[mylead.applicantStatus]?.map(
                        (option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        )
                      )}
                  </select>
                </div>
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Manager
                  </p>

                  <select
                    name="leadProvedByAdminId"
                    id="leadProvedByAdminId"
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    value={mylead.leadProvedByAdminId}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Select Manager
                    </option>
                    <option value="HBR1">Admin</option>
                    {/* <option>Short Listed</option>
                    <option>Not Short Listed</option> */}
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Selected Location
                  </p>

                  <select
                    name="newLocation" // Ensure this matches the state key
                    className="select select-bordered w-full max-w-xs"
                    value={mylead.newLocation}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Locations
                    </option>
                    {location.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className="text-gray-700 dark:text-gray-200"
                    for="offeredCTC"
                  >
                    Offered CTC (lacs)
                  </label>
                  <input
                    name="offeredCTC" // Ensure this matches the state key
                    id="offeredCTC"
                    value={mylead.offeredCTC}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                </div>

                <div>
                  <label
                    className="text-gray-700 dark:text-gray-200"
                    for="text"
                  >
                    Offered Role role
                  </label>
                  <input
                    name="newRole" // Ensure this matches the state key
                    id="newRole"
                    value={mylead.newRole}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                </div>

                <div>
                  <label
                    className="text-gray-700 dark:text-gray-200"
                    for="passwordConfirmation"
                  >
                    Company Department
                  </label>
                  <input
                    name="newDepartment" // Ensure this matches the state key
                    id="newDepartment"
                    value={mylead.newDepartment}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                </div>
                <div>
                  <label
                    className="text-gray-700 dark:text-gray-200"
                    for="passwordConfirmation"
                  >
                    Company Name
                  </label>
                  <input
                    name="newCompanyName" // Ensure this matches the state key
                    id="newCompanyName"
                    value={mylead.newCompanyName}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                </div>
                <div>
                  <label
                    className="text-gray-700 dark:text-gray-200"
                    for="text"
                  >
                    Industry
                  </label>
                  <input
                    name="newIndustry" // Ensure this matches the state key
                    id="newIndustry"
                    value={mylead.newIndustry}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 my-2"
                    for="username"
                  >
                    Notes
                  </p>
                  <textarea
                    name="remark" // Ensure this matches the state key
                    id="remark"
                    value={mylead.remark}
                    onChange={handleChangeArray} // Use the generic handleChange for all form elements
                    className="textarea textarea-bordered w-full"
                    placeholder="Add Additional Notes Here"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <div className="divider py-4"></div>
              <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
                Manager
              </h2>
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Client Selection Status
                  </p>

                  <select
                    name="clientStatus" // Ensure this matches the state key
                    id="clientStatus"
                    value={mylead.clientStatus}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Client Selection Status
                    </option>
                    <option value="clientSelected">Client Selected</option>
                    <option value="clientRejected">Client Rejected</option>
                  </select>
                </div>
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Client Feedback
                  </p>

                  <select
                    name="clientFeedback" // Ensure this matches the state key
                    id="clientFeedback"
                    value={mylead.clientFeedback}
                    onChange={handleChangeArray} // Use the generic handleChange for all form elements
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Client Feedback
                    </option>
                    {mylead.clientStatus &&
                      clientFeedbackList[mylead.clientStatus]?.map(
                        (option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        )
                      )}
                  </select>
                </div>
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Candidate Joining Status
                  </p>

                  <select
                    name="candidateStatus" // Ensure this matches the state key
                    id="candidateStatus"
                    value={mylead.candidateStatus}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Candidate Joining Status
                    </option>
                    <option value="applicationPhases">
                      Application Phases
                    </option>
                    <option value="offerPhases">Offer Phases</option>
                    <option value="preJoiningPhases">Pre-Joining Phases</option>
                    <option value="joiningPhases">Joining Phases</option>
                  </select>
                </div>
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 pb-2"
                    htmlFor="username"
                  >
                    Candidate Feedback
                  </p>

                  <select
                    name="candidateFeedback" // Ensure this matches the state key
                    id="candidateFeedback"
                    value={mylead.candidateFeedback}
                    onChange={handleChangeArray} // Use the generic handleChange for all form elements
                    className="select select-bordered w-full max-w-xs"
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Candidate Feedback
                    </option>
                    {mylead.candidateStatus &&
                      candidateStatusList[mylead.candidateStatus]?.map(
                        (option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
                <div>
                  <p
                    className="text-gray-700 dark:text-gray-200 my-2"
                    for="username"
                  >
                    Offer Status
                  </p>
                  <textarea
                    name="offerStatus" // Ensure this matches the state key
                    id="offerStatus"
                    value={mylead.offerStatus}
                    onChange={handleChange} // Use the generic handleChange for all form elements
                    className="textarea textarea-bordered w-full"
                    placeholder="Add Offer Status Notes Here"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              {candidateProfileDisplay(
                candidateDetail,
                setCandidateDetail,
                location
              )}
            </form>
            <div className="modal-action pt-4">
              <form method="dialog">
                <button
                  className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                  onClick={() => {
                    handleSumit();
                  }}
                >
                  Save
                </button>
                <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
                  Cancel
                </button>
              </form>
            </div>
          </section>
        </div>
      </>
    </>
  );
}

function candidateProfileDisplay(
  candidateDetail,
  setCandidateDetail,
  location
) {
  // const [selected, setSelected] = useState([]);

  // const onAdd = useCallback(
  //   (newTag) => {
  //     setSelected([...selected, newTag]);
  //   },
  //   [selected]
  // );

  // const onDelete = useCallback(
  //   (tagIndex) => {
  //     setSelected(selected.filter((_, i) => i !== tagIndex));
  //   },
  //   [selected]
  // );
  // function onAdd(newTag) {
  //   setSelected([...selected, newTag]);
  // }

  // function onDelete(tagIndex) {
  //   setSelected(selected.filter((_, i) => i !== tagIndex));
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: value, // Dynamically update the right property based on the element's name
    }));
  };
  const handleChangeArray = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: [value], // Dynamically update the right property based on the element's name
    }));
  };
  if (!candidateDetail) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="divider py-4"></div>
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
        Candidate Details
      </h2>
      <div className="my-4">
        <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1 ">
          <div className="badge badge-lg  badge-outline">
            Creation Time: {candidateDetail.profileCreationTime}
          </div>
          <br />
          <div className="badge badge-lg  badge-outline">
            Created By: {candidateDetail.profileCreatedById}
          </div>
        </h2>
        {/* <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1">
                  <div className="badge badge-lg  badge-outline">
                    Candidate Profile ID: {mylead.candidateProfileId}
                  </div>
                </h2>
                <div className="badge badge-lg badge-ghost">
                  {mylead.name}, {mylead.currentLocation}
                </div>
                <div className="badge badge-lg badge-ghost">
                  Contact : {mylead.phoneNumber}
                </div> */}
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="username">
            Name
          </label>
          <input
            name="name"
            value={candidateDetail.name || ""}
            onChange={handleChange}
            id="name"
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="text">
            Email ID
          </label>
          <input
            id="emailId"
            name="emailId"
            value={candidateDetail.emailId}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="phoneNumber">
            {" "}
            Contact Number
          </label>
          {/* <input
            name="phoneNumber"
            value={candidateDetail.phoneNumber}
            onChange={handleChange}
            id="phoneNumber"
            type="tel"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
          <input
            name="phoneNumber"
            value={candidateDetail.phoneNumber}
            onChange={handleChange} // Use the provided handleChange function directly
            id="phoneNumber"
            type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
            pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="alternativeNumber"
          >
            {" "}
            Alternative Phone Number
          </label>
          <input
            name="alternativeNumber"
            value={candidateDetail.alternativeNumber}
            onChange={handleChange}
            id="alternativeNumber"
            type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
            pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="currentLocation"
          >
            Current Location
          </label>
          <input
            name="currentLocation"
            id="currentLocation"
            value={candidateDetail.currentLocation}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="passwordConfirmation"
          >
            Preferred Locations
          </label>
          <input
            name="preferredLocations"
            id="passwordConfirmation"
            value={candidateDetail.preferredLocations}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <p
            className="text-gray-700 dark:text-gray-200 pb-2"
            htmlFor="username"
          >
            Gender
          </p>

          <select
            name="gender" // Ensure this matches the state key
            className="select select-bordered w-full max-w-xs"
            value={candidateDetail.gender}
            onChange={handleChange} // Use the generic handleChange for all form elements
            aria-label="Gender"
          >
            <option disabled value="">
              Gender Options
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="trans">Trans</option>
          </select>
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="maritalStatus"
          >
            Marital Status
          </label>
          <input
            name="maritalStatus"
            id="maritalStatus"
            value={candidateDetail.maritalStatus}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="homeTownCity"
          >
            Home Town City
          </label>
          <input
            name="homeTownCity"
            id="homeTownCity"
            value={candidateDetail.homeTownCity}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        {/* <div>
          <p
            className="text-gray-700 dark:text-gray-200 pb-2"
            htmlFor="username"
          >
            Selected Location
          </p>

          <select
            className="select select-bordered w-full max-w-xs"
            value={candidateDetail.homeTownCity}
            defaultValue=""
            aria-label="Application Status"
          >
            <option disabled value="">
              Locations
            </option>
            {location.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div> */}
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="pinCode">
            Pin Code
          </label>
          <input
            name="pinCode"
            id="pinCode"
            value={candidateDetail.pinCode}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="dateOfBirth">
            Date of Birth
          </label>
          <input
            name="dateOfBirth"
            id="dateOfBirth"
            value={candidateDetail.dateOfBirth}
            handleChange={handleChange}
            type="date"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="permanentAddress"
          >
            Permanent Address
          </label>
          <input
            name="permanentAddress"
            id="permanentAddress"
            value={candidateDetail.permanentAddress}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="text">
            Total Experience
          </label>
          <input
            name="totalExperience"
            id="totalExperience"
            value={candidateDetail.totalExperience}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="currCompanyName"
          >
            Current Company Name
          </label>
          <input
            name="currCompanyName"
            id="currCompanyName"
            value={candidateDetail.currCompanyName}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="currCompanyDesignation"
          >
            Current Company Designation
          </label>
          <input
            name="currCompanyDesignation"
            id="currCompanyDesignation"
            value={candidateDetail.currCompanyDesignation}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="department">
            Current Department
          </label>
          <input
            name="department"
            id="department"
            value={candidateDetail.department}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="role">
            Role
          </label>
          <input
            name="role"
            id="role"
            value={candidateDetail.role}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="industry">
            Current Industry
          </label>
          <input
            name="industry"
            id="industry"
            value={candidateDetail.industry}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="keySkills">
            Key Skills
          </label>
          <input
            name="keySkills"
            id="keySkills"
            value={candidateDetail.keySkills}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="annualSalary"
          >
            Annual Salary
          </label>
          <input
            name="annualSalary"
            id="annualSalary"
            value={candidateDetail.annualSalary}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="noticePeriodAvailabilityToJoin"
          >
            Notice Period Availability To Join
          </label>
          <input
            name="noticePeriodAvailabilityToJoin"
            id="noticePeriodAvailabilityToJoin"
            value={candidateDetail.noticePeriodAvailabilityToJoin}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="resumeHeadline"
          >
            Resume Headline
          </label>
          <input
            name="resumeHeadline"
            id="resumeHeadline"
            value={candidateDetail.resumeHeadline}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <p
            className="text-gray-700 dark:text-gray-200 my-2"
            for="resumeSummary"
          >
            Notes
          </p>
          <textarea
            name="resumeSummary"
            id="resumeSummary"
            value={candidateDetail.resumeSummary}
            onChange={handleChange}
            className="textarea textarea-bordered w-full"
            placeholder="Add Additional Notes Here"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        {/* <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="resumeSummary"
          >
            resumeSummary
          </label>
          <input
            id="resumeSummary"
            value={candidateDetail.resumeSummary}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div> */}
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="underGraduationDegree"
          >
            UG Graduation Degree
          </label>
          <input
            name="underGraduationDegree"
            id="underGraduationDegree"
            value={candidateDetail.underGraduationDegree}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="underGraduationDegree"
          >
            UG Specialization
          </label>
          <input
            name="ugSpecialization"
            id="ugSpecialization"
            value={candidateDetail.ugSpecialization}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="ugUniversityInstituteName"
          >
            UG University Institute Name
          </label>
          <input
            name="ugUniversityInstituteName"
            id="ugUniversityInstituteName"
            value={candidateDetail.ugUniversityInstituteName}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="ugGraduationYear"
          >
            UG Graduation Year
          </label>
          <input
            name="ugGraduationYear"
            id="ugGraduationYear"
            value={candidateDetail.ugGraduationYear}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="postGraduationDegree"
          >
            Post Graduation Degree
          </label>
          <input
            name="postGraduationDegree"
            id="postGraduationDegree"
            value={candidateDetail.postGraduationDegree}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgSpecialization"
          >
            PG Specialization
          </label>
          <input
            name="pgSpecialization"
            id="pgSpecialization"
            value={candidateDetail.pgSpecialization}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgUniversityInstituteName"
          >
            PG University Institute Name
          </label>
          <input
            name="pgUniversityInstituteName"
            id="pgUniversityInstituteName"
            value={candidateDetail.pgUniversityInstituteName}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgGraduationYear"
          >
            PG Graduation Year
          </label>
          <input
            name="pgGraduationYear"
            id="pgGraduationYear"
            value={candidateDetail.pgGraduationYear}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateDegree"
          >
            Doctorate Degree
          </label>
          <input
            name="doctorateDegree"
            id="doctorateDegree"
            value={candidateDetail.doctorateDegree}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateSpecialization"
          >
            Doctorate Specialization
          </label>
          <input
            name="doctorateSpecialization"
            id="doctorateSpecialization"
            value={candidateDetail.doctorateSpecialization}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateUniversityInstituteName"
          >
            Doctorate University Institute Name
          </label>
          <input
            name="doctorateUniversityInstituteName"
            id="doctorateUniversityInstituteName"
            value={candidateDetail.doctorateUniversityInstituteName}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateGraduationYear"
          >
            Doctorate Graduation Year
          </label>
          <input
            name="doctorateGraduationYear"
            id="doctorateSpecialization"
            value={candidateDetail.doctorateGraduationYear}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="workPermitForUsa"
          >
            Work Permit For Usa
          </label>
          <input
            name="workPermitForUsa"
            id="workPermitForUsa"
            value={candidateDetail.workPermitForUsa}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
      </div>
    </>
  );
}
