import axios from "axios";

export async function AddCandidate(Host, data) {
  try {
    console.log("base ", Host, data);
    const response = await axios.post(`${Host}/api/add_candidate`, data);

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
