import React from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { getDatabase, ref, set, update, get, push } from "firebase/database";
// import { db } from "../../Auth/Firebase/firebase";
// import { format } from "date-fns";

// Import ContextAPI
import { useContext } from "react";
import SystemContext from "../ContextAPI/SystemContext/SystemContext";
import moment from "moment-timezone";
const MAX_RETRIES = 3; // Maximum number of re-authentication attempts
const RETRY_INTERVAL = 5000; // Retry every 5 seconds
const LOGOUT_TIME = 45;
const ACTIVE_REFRESH = 5;

function Wrapper({ children }) {
  const Host = process.env.REACT_APP_API_URL || "http://localhost:5001";
  const navigate = useNavigate();
  const systemContext = useContext(SystemContext);
  let accessLevel = null;
  let user = null;
  //   const checkAccessLevel = () => {
  //     try {
  //       const userString = localStorage.getItem("user");
  //       // Only parse if userString is not null
  //       if (userString) {
  //         const temp = JSON.parse(userString);
  //         const tempUser = JSON.parse(userString);
  //         user = tempUser;
  //         accessLevel = temp.accessLevel;
  //         // console.log("accessLevel: ", accessLevel);
  //         // console.log(temp);
  //       } else {
  //         // Handle the case where the user key is not found in localStorage
  //         console.log("User not found in localStorage.");
  //       }
  //     } catch (error) {
  //       // Handle any parsing errors
  //       console.error("Error parsing user data from localStorage:", error);
  //     }
  //   };
  const mouseClickListener = async (event) => {
    // Assuming checkAccessLevel() updates an accessLevel variable and 'user' is available in this scope
    // checkAccessLevel();
    if (!systemContext.serverConnectionStatus) {
      logout();
      return;
    }
    await refreshloginLogic();
    // console.log("Component clicked!");
  };

  useEffect(() => {
    const storedData = localStorage.getItem("user");
    // Check if storedData is not null before parsing
    const user = storedData ? JSON.parse(storedData) : null;
    // console.log("Wrapper : ", user);

    // Now check if user is not null and then check for the token
    if (user && user.token) {
      const accessToken = user.token;

      if (!accessToken) {
        // navigate("/login");
        console.log("Navigating to login @22");
      } else {
        const tokenCreationTime = new Date(
          localStorage.getItem("tokenCreationTime")
        );
        const oneHourAgo = new Date(
          new Date().getTime() - LOGOUT_TIME * 60 * 1000
        );
        if (tokenCreationTime <= oneHourAgo) {
          console.log("Navigating to login @23");
          navigate("/login");
        }
      }
    } else {
      console.log("Navigating to login @24");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      const storedData = localStorage.getItem("user");
      // Check if storedData is not null before parsing
      const user = storedData ? JSON.parse(storedData) : null;
      // console.log("Wrapper : ", user);
      if (user && user.token) {
        const accessToken = user.token || "";
        if (!accessToken) {
          clearInterval(interval);
          navigate("/login");
          return;
        }
        const tokenCreationTime = new Date(
          localStorage.getItem("tokenCreationTime")
        );
        const oneHourAgo = new Date(
          new Date().getTime() - LOGOUT_TIME * 60 * 1000
        );
        // const oneHourAgo = new Date(new Date().getTime() - 10000);
        if (tokenCreationTime <= oneHourAgo) {
          clearInterval(interval);
          console.log("Navigating to login @26");

          navigate("/login");
        }
      } else {
        console.log("Navigating to login @27");
        navigate("/login");
      }
    }, RETRY_INTERVAL);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [navigate]); // Include navigate in the dependency array

  const refreshloginLogic = async () => {
    // console.log("refreshloginLogic Called");
    const storedData = localStorage.getItem("user");
    // Check if storedData is not null before parsing
    const user = storedData ? JSON.parse(storedData) : null;
    // console.log("Wrapper : ", user);
    if (user && user.token) {
      const accessToken = user.token;

      const tokenCreationTime = new Date(
        localStorage.getItem("tokenCreationTime")
      );
      const oneHourAgo = new Date(
        new Date().getTime() - ACTIVE_REFRESH * 60 * 1000
      );
      // const oneHourAgo = new Date(new Date().getTime() - 2000);
      if (tokenCreationTime <= oneHourAgo) {
        // Token is older than an hour, re-authenticate
        if (accessToken) {
          // Logic to re-authenticate and get a new token
          // You'll need to make an API call to your backend here
          // console.log("Email: ", email, password);
          await reauthenticate(Host, accessToken)
            .then((newToken) => {
              // Update user object with new token
              user.token = newToken;
              // Update local storage with new user object
              localStorage.setItem("user", JSON.stringify(user));
              console.log("Token Refresh: ", newToken);

              // Update token creation time
              localStorage.setItem(
                "tokenCreationTime",
                new Date().toISOString()
              );
            })
            .catch(() => {
              // Handle failed re-authentication
              // localStorage.removeItem("user");
              // localStorage.removeItem("tokenCreationTime");
              // navigate("/login");
              logout();
            });
        } else {
          // No credentials stored, redirect to login
          navigate("/login");
          console.log("Navigating to login @25");
        }
      }
    } else {
      // Token is still valid, no need to re-authenticate
      console.log("No token found. Redirecting to login.");
    }
  };
  function logout() {
    // Clear the user object from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("tokenCreationTime");
    // Redirect to the login page
    navigate("/login");
  }

  return (
    <>
      <>
        <div
          onClick={() => {
            mouseClickListener();
          }}
        >
          {children}
        </div>
      </>
    </>
  );
}

async function reauthenticate(host, token) {
  // Assuming the token is stored in the localStorage
  const response = await fetch(`${host}/api/auth/refreshlogin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // Sending the token as a bearer token in the Authorization header
      "Content-Type": "application/json",
    },
    // No body is sent since we're only sending the token in the header
  });
  console.log("Refresh Login Data: ", response);
  const data = await response.json();
  return data.token;
}
export default Wrapper;
