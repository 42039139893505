import react from "@heroicons/react";

import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";

export default function UserStats() {
  // Define your series data
  const series = [
    {
      name: "Series 1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ];

  // Define your chart options
  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "My First ApexCharts Chart",
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
}
