import React, { useState, useEffect } from "react";

// Internal Component Import
import Alert from "../CommonComponents/Alert";

// import Context
import { useContext } from "react";
import { useNavigate } from "react-router";
import SystemContext from "../ContextAPI/SystemContext/SystemContext";

// Import External Components
import LinearProgress from "@mui/material/LinearProgress";

export default function LoginPage() {
  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();

  const [loginID, setLoginID] = useState("");
  const [password, setPassword] = useState("");
  const [process, setProcess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Your ID or Password is incorrect"
  );
  useEffect(() => {
    console.log("Data : ", loginID, password);
  });

  async function handleSubmit(event) {
    setShowAlert(false);
    setErrorMessage("");

    event.preventDefault(); // Prevent the form from submitting

    setProcess(true); // Indicate the start of the process

    try {
      // Assuming setdelay is just for demonstration and doesn't affect authentication
      await systemContext.setdelay(2000); // Simulate a delay

      // Attempt to authenticate the user
      const res = await systemContext.authenticated(loginID, password);

      // Check if the authentication was successful
      if (res.status === 200) {
        console.log("Status Code 200");
        // Redirect the user to the home page on successful authentication
        setErrorMessage("");
        setShowAlert(false);

        console.log("Navigating to dashboard @22");
        navigate("/");
      } else {
        // Handle other response codes accordingly
        console.error("Authentication failed with status:", res.status);
      }
    } catch (error) {
      // Check if the error is a network error (no response from the server)
      if (!error.response) {
        // Handle network errors
        setShowAlert(true);
        setErrorMessage(
          "Server not responding or network issue. Please check your connection and try again."
        );
      } else {
        const { status } = error.response;
        setShowAlert(true);
        // Handle 401 Unauthorized
        if (status === 401) {
          setErrorMessage("Please enter valid ID or password.");
        }
        // Handle 500 Internal Server Error and other server-related errors
        else if (status >= 500) {
          setErrorMessage("Internal server error. Please try again later.");
        }
        // Handle other errors
        else {
          setErrorMessage("An error occurred. Please try again.");
        }
      }
    } finally {
      setProcess(false); // Indicate the end of the process
    }
  }

  function returnAlert() {
    if (showAlert) {
      return <Alert errorMessage={errorMessage} />;
    } else {
      return "";
    }
  }

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            {returnAlert()}
            <div className="px-6 py-4">
              <div className="flex justify-center mx-auto">
                <img
                  className="w-auto h-7 sm:h-8"
                  // src="https://merakiui.com/images/logo.svg"
                  src="/hirewynk_logo.png"
                  alt=""
                />
              </div>

              <h3 className="mt-3 text-xl font-medium text-center text-gray-600 dark:text-gray-200">
                Welcome Back
              </h3>

              <p className="mt-1 text-center text-gray-500 dark:text-gray-400">
                Login or create account
              </p>

              <form>
                <div className="w-full mt-4">
                  <input
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    placeholder="Email Address"
                    aria-label="Email Address"
                    value={loginID}
                    onChange={(e) => setLoginID(e.target.value)}
                  />
                </div>

                <div className="w-full mt-4">
                  <input
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="flex items-center justify-between mt-4">
                  <a
                    href=""
                    className="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500"
                  >
                    Forget Password?
                  </a>

                  {/* <button
                    className="px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Sign In
                  </button> */}
                  <button
                    className="px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
            {process && <LinearProgress />}
            <div className="flex items-center justify-center py-4 text-center bg-gray-50 dark:bg-gray-700">
              <span className="text-sm text-gray-600 dark:text-gray-200">
                Experience issues while login?{" "}
              </span>

              <a
                href=""
                className="mx-2 text-sm font-bold text-blue-500 dark:text-blue-400 hover:underline"
              >
                Report Us
              </a>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
