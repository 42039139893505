import React, { useEffect, useState } from "react";
// Import Context
import { useContext } from "react";
import SystemContext from "../../../ContextAPI/SystemContext/SystemContext";

export default function FileUploadHandle({
  windowState,
  process,
  fileInstance,
  setFileInstance,
  fileMetadata,
  setFileMetadata,
}) {
  const systemContext = useContext(SystemContext);
  const [processing, setProcessing] = useState(false);
  //   const [fileMetadata, setFileMetadata] = useState({
  //     name: "",
  //     size: 0,
  //     sizeUnit: "",
  //     lastModifiedDate: "",
  //     lastModifiedTime: "",
  //     type: "",
  //     extension: "",
  //   });
  function renderSelect() {
    if (fileMetadata.name === "") {
      // If so, don't render anything
      return UploadButtonShow(
        processing,
        setProcessing,
        fileInstance,
        setFileInstance,
        fileMetadata,
        setFileMetadata
      );
    } else {
      return UploadDataStructure(fileMetadata);
    }
  }
  useEffect(() => {
    if (!windowState) {
      setFileMetadata({
        name: "",
        size: 0,
        sizeUnit: "",
        lastModifiedDate: "",
        lastModifiedTime: "",
        type: "",
        extension: "",
      });
    }
  }, [windowState]);
  // useEffect(() => {
  //   console.log("Upload Clicked ", process);
  //   systemContext.uploadLeadData(fileInstance);
  // }, [process]);
  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            width: "100%",
          }}
        >
          {renderSelect()}
        </div>
      </>
    </>
  );
}

function UploadButtonShow(
  processing,
  setProcessing,
  fileInstance,
  setFileInstance,
  fileMetadata,
  setFileMetadata
) {
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFileInstance(file);
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileMetadata({
        name: file.name,
        size:
          file.size / 1024 > 1024
            ? (file.size / (1024 * 1024)).toFixed(2)
            : (file.size / 1024).toFixed(2),
        sizeUnit: file.size / 1024 > 1024 ? "MB" : "KB",
        lastModifiedDate: new Date(file.lastModified).toLocaleDateString(),
        lastModifiedTime: new Date(file.lastModified).toLocaleTimeString(),
        type: file.type,
        extension: file.name.split(".").pop(), // Get the file extension
      });

      setProcessing(true);
    } else {
      alert("Please select an XLSX file.");
    }
  };

  return (
    <>
      <div style={{}}>
        <input
          className="mt-4"
          accept=".xlsx"
          style={{ display: "block" }}
          id="raised-button-file"
          type="file"
          onChange={handleFileSelect}
        />
      </div>
    </>
  );
}

function UploadDataStructure(fileMetadata) {
  return (
    <>
      <div className="max-w-2xl px-8 py-4 bg-white rounded-lg my-4">
        <div className="flex items-center justify-between">
          <span className="text-sm font-light text-gray-600 dark:text-gray-400">
            Last Modified : {fileMetadata.lastModifiedDate}{" "}
            {fileMetadata.lastModifiedTime}
          </span>
          <a
            className="px-3 py-1 text-sm font-bold text-gray-100 transition-colors duration-300 transform bg-gray-600 rounded cursor-pointer hover:bg-gray-500"
            tabIndex="0"
            role="button"
          >
            {/* {fileMetadata.extension} */}
            Size: {fileMetadata.size}
            {"  "} {fileMetadata.sizeUnit}
          </a>
        </div>

        <div className="mt-2">
          <div
            href="#"
            className="text-xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 "
            tabIndex="0"
            role="link"
          >
            {fileMetadata.name}
          </div>
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            {fileMetadata.type}
          </p>
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            File Extension: {fileMetadata.extension}
          </p>
        </div>

        {/* <div className="flex items-center justify-between mt-4">
          <a
            href="#"
            className="text-blue-600 dark:text-blue-400 hover:underline"
            tabIndex="0"
            role="link"
          >
            Read more
          </a>

          <div className="flex items-center">
            <img
              className="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block"
              src="https://images.unsplash.com/photo-1502980426475-b83966705988?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=40&q=80"
              alt="avatar"
            />
            <a
              className="font-bold text-gray-700 cursor-pointer dark:text-gray-200"
              tabIndex="0"
              role="link"
            >
              Khatab wedaa
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
}
