import axios from "axios";

export async function AddCandidateByLink(host, data) {
  try {
    // Log the base URL and the data to be sent for debugging purposes
    console.log("Base URL:", host, "Data:", data);

    // Making a POST request to the backend with the candidate's data
    const response = await axios.post(`${host}/api/save_link_data`, data);

    // Return the response received from the backend server
    return response;
  } catch (error) {
    // Log the error if the request fails
    console.error("Error:", error);
    throw error; // Rethrow the error to handle it outside this function if needed
  }
}
