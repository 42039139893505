import React from "react";
import { useContext, useEffect, useState } from "react";

// Import Components
import ViewLead from "./ViewLead";

// Import Context API
import MainContext from "../../ContextAPI/MainContext/MainContext";

export default function CandidateLead({ viewStatus }) {
  const mainContext = useContext(MainContext);
  const [liveLeads, setLiveLeads] = useState(mainContext.myLeads);
  const [processState, setProcessState] = useState(false);

  useEffect(() => {
    mainContext.requestMyClaimLeads();
  }, []);

  useEffect(() => {
    let filteredLeads = [];

    if (viewStatus === "viewAll") {
      filteredLeads = mainContext.myLeads;
    } else if (viewStatus === "viewPendingReview") {
      filteredLeads = mainContext.myLeads.filter(
        (lead) =>
          lead.applicantStatus !== null &&
          lead.applicantStatus !== "" &&
          lead.applicantStatus !== "NA"
      );
    } else if (viewStatus === "viewNew") {
      filteredLeads = mainContext.myLeads.filter(
        (lead) =>
          lead.applicantStatus === null ||
          lead.applicantStatus === "" ||
          lead.applicantStatus === ""
      );
    }

    setLiveLeads(filteredLeads);
    console.log("Leads Refreshed");
  }, [mainContext.myLeads, viewStatus]);

  useEffect(() => {
    setProcessState(true);
    const timer = setTimeout(() => {
      setProcessState(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [viewStatus]);

  return (
    <>
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                {showLeads(viewStatus, processState, liveLeads)}
              </table>
            </div>
          </div>
        </div>
      </div>

      {Pagination()}

      {/* {processState && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <span>Loading...</span>
          </div>
        </div>
      )} */}
    </>
  );
}

function showLeads(viewStatus, processState, liveLeads) {
  return (
    <>
      <>
        {true && (
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th
                scope="col"
                className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <button className="flex items-center gap-x-3 focus:outline-none">
                  <span>Name</span>
                </button>
              </th>
              <th
                scope="col"
                className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                Status
              </th>
              {viewStatus === "viewPendingReview" && (
                <th
                  scope="col"
                  className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  Manager
                </th>
              )}
              <th
                scope="col"
                className="px-3.5 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                Curr. Location
              </th>
              <th
                scope="col"
                className="px-3.5 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                Pref. Location
              </th>

              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                Experience
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                Role
              </th>

              <th scope="col" className="relative py-3.5 px-4">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
        )}
        {!processState && liveLeads.length !== 0 && (
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
            {liveLeads.map((lead) => (
              <tr key={lead.id}>
                <td
                  className="px-4 py-4 text-sm font-medium whitespace"
                  style={{ minWidth: "100px", maxWidth: "150px" }}
                >
                  <div>
                    <h2 className="font-medium text-gray-800 dark:text-white ">
                      {lead.name}
                    </h2>
                  </div>
                </td>

                <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                  <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                    {lead.applicantStatus ? lead.applicantStatus : "N/A"}
                  </div>
                </td>
                {viewStatus === "viewPendingReview" && (
                  <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                    <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                      {lead.leadProvedByAdminId
                        ? lead.leadProvedByAdminId
                        : "N/A"}
                    </div>
                  </td>
                )}
                <td
                  className="px-4 py-4 text-sm font-medium whitespace"
                  style={{ minWidth: "100px", maxWidth: "150px" }}
                >
                  <div>
                    <h2 className="font-medium text-gray-500 dark:text-white ">
                      {lead.currentLocation}
                    </h2>
                  </div>
                </td>

                <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                  <div>
                    <h2 className="font-medium text-gray-500 dark:text-white "></h2>
                    <div
                      className="flex flex-wrap gap-1"
                      style={{ maxWidth: "200px" }}
                    >
                      {lead.preferredLocations.map((location, index) => (
                        <div
                          key={index}
                          className="badge badge-outline flex-shrink-0"
                        >
                          {location}
                        </div>
                      ))}
                    </div>
                  </div>
                </td>

                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div>
                    <h4 className="text-gray-700 dark:text-gray-200">
                      {lead.totalExperience}
                    </h4>
                  </div>
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div>
                    <div
                      className="flex flex-wrap gap-1"
                      style={{ maxWidth: "200px" }}
                    >
                      {lead.role.map((role, index) => (
                        <div
                          class="tooltip tooltip-bottom"
                          data-tip={`${role}`}
                        >
                          <div
                            key={index}
                            className="badge badge-ghost flex-wrap flex-shrink-1"
                          >
                            {role.length > 25
                              ? `${role.substring(0, 22)}...`
                              : role}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <ViewLead lead={lead} />
                </td>
              </tr>
            ))}
          </tbody>
        )}
        {processState && <> {PlaceHolder(liveLeads)}</>}
        {liveLeads.length === 0 && (
          <>
            {/* <tr> */}
            <div className="px-4 py-4 text-sm text-center w-100">
              No Live Leads To Show{" "}
            </div>
            {/* </tr> */}
          </>
        )}
      </>
    </>
  );
}
function PlaceHolder(liveLeads) {
  return (
    <>
      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
        {Array(5)
          .fill()
          .map((_, index) => (
            <tr key={index}>
              <td
                className="px-4 py-4 text-sm font-medium whitespace"
                style={{ minWidth: "100px", maxWidth: "150px" }}
              >
                <div>
                  <h2 className="font-medium text-gray-800 dark:text-white ">
                    {/* {lead.name} */}
                    <div className="skeleton h-6 w-full"></div>
                  </h2>
                </div>
              </td>

              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                {/* <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                {lead.applicantStatus ? lead.applicantStatus : "N/A"}
              </div> */}
                <div className="skeleton h-4 w-full"></div>
              </td>
              <td
                className="px-4 py-4 text-sm font-medium whitespace"
                style={{ minWidth: "100px", maxWidth: "150px" }}
              >
                <div>
                  {/* <h2 className="font-medium text-gray-500 dark:text-white ">
                  {lead.currentLocation}
                </h2> */}
                  <div className="skeleton h-2 w-full my-1"></div>
                  <div className="skeleton h-2 w-full my-1"></div>

                  {/* <h2 className="font-medium text-gray-500 dark:text-white truncate">
                      {lead.currentState}
                    </h2> */}
                </div>
              </td>

              <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                <div>
                  <h2 className="font-medium text-gray-500 dark:text-white ">
                    {/* {lead.preferredLocations} */}
                  </h2>
                  <div
                    className="flex flex-wrap gap-1"
                    style={{ maxWidth: "200px" }}
                  >
                    {/* {lead.preferredLocations.map((location, index) => (
                    <div
                      key={index}
                      className="badge badge-outline flex-shrink-0"
                    >
                      {location}
                    </div>
                  ))} */}
                    <div className="skeleton h-1 w-full my-1"></div>
                    <div className="skeleton h-1 w-full my-1"></div>
                    <div className="skeleton h-1 w-full my-1"></div>
                  </div>
                </div>
              </td>

              <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div>
                  {/* <h4 className="text-gray-700 dark:text-gray-200">
                  {lead.totalExperience}
                </h4> */}
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </td>
              <td className="px-4 py-4 text-sm whitespace-nowrap">
                <div>
                  <div
                    className="flex flex-wrap gap-1"
                    style={{ maxWidth: "200px" }}
                  >
                    {/* {lead.role.map((role, index) => (
                    <div class="tooltip tooltip-bottom" data-tip={`${role}`}>
                      <div
                        key={index}
                        className="badge badge-ghost flex-wrap flex-shrink-1"
                      >
                        {role.length > 25
                          ? `${role.substring(0, 22)}...`
                          : role}
                      </div>
                    </div>
                  ))} */}
                    <div className="skeleton h-4 w-full"></div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-4 text-sm whitespace-nowrap">
                {/* <ViewLead lead={lead} /> */}
                {/* <div className="skeleton h-4 w-full"></div> */}
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
}

function Pagination() {
  return (
    <>
      <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
        <div className="text-sm text-gray-500 dark:text-gray-400">
          Page{" "}
          <span className="font-medium text-gray-700 dark:text-gray-100">
            1 of 10
          </span>
        </div>

        <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
          <a
            href="#"
            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </a>

          <a
            href="#"
            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
          >
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}
