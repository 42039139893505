import axios from "axios";

export async function GenerateLinkForForm(Host, token) {
  try {
    console.log("base ", Host);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Including the bearer token in the request headers
      },
    };
    const response = await axios.get(`${Host}/api/generate_link`, config);

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
