import "./index.css"; // Adjust the path according to your project structure
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Component Imports
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
import Candidates from "./components/Candidates";
import LoginPage from "./Auth/LoginPage";
import ExternalCandidate from "./components/ExternalCandidate";

// Wrapper Imports
import Wrapper from "./Auth/Wrapper";

// Import Context
import MainStates from "./ContextAPI/MainContext/MainStates";
import SystemStates from "./ContextAPI/SystemContext/SystemStates";

// Import Wrapper
import SystemAlertWrapper from "./CommonComponents/SystemAlertWrapper";
import ProfileSettings from "./components/ProfileSettings";
import SignOutPage from "./Auth/SignOutPage";
import CRMSettings from "./components/CRMSettings";
function App() {
  return (
    <>
      <>
        <SystemStates>
          <MainStates>
            {/* <Navbar /> */}

            <BrowserRouter>
              <Routes>
                {/* Define the root path with Wrapper and Dashboard */}
                <Route
                  path="/"
                  element={
                    <Wrapper>
                      <SystemAlertWrapper>
                        <Navbar>
                          <Dashboard />
                        </Navbar>
                      </SystemAlertWrapper>
                    </Wrapper>
                  }
                />
                {/* Define the login path */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/singout" element={<SignOutPage />} />
                <Route path="/candidate-info" element={<ExternalCandidate />} />

                <Route
                  path="/candidates"
                  element={
                    <Wrapper>
                      <SystemAlertWrapper>
                        <Navbar>
                          <Candidates />
                        </Navbar>
                      </SystemAlertWrapper>
                    </Wrapper>
                  }
                />
                <Route
                  path="/crm-settings"
                  element={
                    <Wrapper>
                      <SystemAlertWrapper>
                        <Navbar>
                          <CRMSettings />
                        </Navbar>
                      </SystemAlertWrapper>
                    </Wrapper>
                  }
                />
                <Route
                  path="/profile-settings"
                  element={
                    <Wrapper>
                      <SystemAlertWrapper>
                        <Navbar>
                          <ProfileSettings />
                        </Navbar>
                      </SystemAlertWrapper>
                    </Wrapper>
                  }
                />

                {/* <Route path="/FormPage" element={<FormPage />} /> */}
                {/* <Route path="/SuccessPage" element={<SuccessPage />} /> */}
                {/* Define the test path */}
                {/* <Route
                  path="/test"
                  element={
                    <Wrapper>
                      <Test />
                    </Wrapper>
                  }
                /> */}
              </Routes>
            </BrowserRouter>
          </MainStates>
        </SystemStates>
      </>
    </>
  );
}

export default App;
