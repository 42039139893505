import react from "@heroicons/react";
import { useState, useEffect } from "react";
// Import Components
import CandidateNavbar from "../CandidatesComponents/CandidateNavbar";
import CandidateRender from "../CandidatesComponents/liveCandidateComponents/CandidateLead";
import CandidateLive from "../CandidatesComponents/CandidateLive";
import MyCandidates from "../CandidatesComponents/MyCandidates";
import OnWatch from "../CandidatesComponents/OnWatch";
import WorkHistory from "../CandidatesComponents/WorkHistory";
const navLinks = [
  {
    name: "Live Candidates",
    path: "livecandidate",
  },
  { name: "My Candidates", path: "mycandidate" },
  { name: "On Watch", path: "onwatch" },
  { name: "History", path: "history" },
];
export default function Candidates() {
  const [selectedTab, setSelectedTab] = useState("livecandidate");
  function TabContent(selectedTab) {
    // Render specific component based on selectedTab
    switch (selectedTab) {
      case navLinks[0].path:
        return <CandidateLive />;
      case navLinks[1].path:
        return <MyCandidates />;
      case navLinks[2].path:
        return <OnWatch />;
      case navLinks[3].path:
        return <WorkHistory />;
      default:
        return <p>Please select a tab.</p>; // Default case when no tab is selected or if the selected tab doesn't match
    }
  }
  return (
    <>
      <>
        <div className="min-h-full">
          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                Candidates
              </h1>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <CandidateNavbar
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                allTabs={navLinks}
              />
              {/* <CandidateLive /> */}

              {TabContent(selectedTab)}
            </div>
          </main>
        </div>
      </>
    </>
  );
}
