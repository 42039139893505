import react from "@heroicons/react";
import { useEffect, useState } from "react";

export default function WarningAlert({ warningMessage, setWarningMessage }) {
  const [display, setDisplay] = useState(true);
  //   function delay(ms) {
  //     // Return a new promise that resolves after 'ms' milliseconds
  //     return new Promise((resolve) => setTimeout(resolve, ms));
  //   }
  //   useEffect(() => {
  //     async function caller() {
  //       await delay(5000);
  //       setDisplay(false);
  //     }
  //     caller();
  //   }, [errorMessage]);
  function closeAlert() {
    // setDisplay(false);
    setWarningMessage("");
  }
  return (
    <div class="flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 my-1">
      <div class="flex items-center justify-center w-12 bg-yellow-400">
        <svg
          class="w-6 h-6 text-white fill-current"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
        </svg>
      </div>

      <div class="px-4 py-2 -mx-3">
        <div class="mx-3">
          <div class="flex justify-between items-center my-2">
            <span class="font-semibold text-yellow-400 dark:text-yellow-300">
              Warning
            </span>
            <div
              onClick={() => {
                closeAlert();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-200">
            {warningMessage}
          </p>
        </div>
      </div>
    </div>
  );
}
