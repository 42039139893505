import React, { useEffect, useState, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";

import moment from "moment-timezone";

// Context API
import { useContext } from "react";
import SystemContext from "../../ContextAPI/SystemContext/SystemContext";

export default function AddCandidatebyForm() {
  const systemContext = useContext(SystemContext);
  const [submitState, setSubmitState] = useState(false);
  const [candidateDetail, setCandidateDetail] = useState();
  const [location, setLocation] = useState([
    // Metro Cities
    "Mumbai",
    "Delhi",
    "Kolkata",
    "Chennai",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    // State Capitals
    "Bhopal",
    "Patna",
    "Panaji",
    "Gandhinagar",
    "Chandigarh",
    "Ranchi",
    "Thiruvananthapuram",
    "Bhubaneswar",
    "Dehradun",
    "Dispur",
    "Shimla",
    "Srinagar",
    "Jaipur",
    "Gangtok",
    "Itanagar",
    "Raipur",
    "Shillong",
    "Aizawl",
    "Kohima",
    "Imphal",
    "Agartala",
    "Lucknow",
    // Union Territory Capitals
    "Port Blair",
    "Daman",
    "Diu",
    "Silvassa",
    "Puducherry",
    "Leh",
    // Popular Cities
    "Varanasi",
    "Agra",
    "Jaipur",
    "Udaipur",
    "Amritsar",
    "Kochi",
    "Mysore",
    "Visakhapatnam",
    "Surat",
    "Indore",
    "Nagpur",
    "Lucknow",
    "Coimbatore",
    "Madurai",
    "Ludhiana",
    "Kanpur",
    "Nashik",
    "Vadodara",
    "Rajkot",
    "Meerut",
    "Faridabad",
    "Ghaziabad",
    "Rajahmundry",
    "Jabalpur",
    "Srinagar",
    "Thane",
    "Aurangabad",
    "Dhanbad",
    "Amravati",
    "Kolhapur",
    "Ajmer",
    "Tiruchirappalli",
    "Jodhpur",
    "Gwalior",
    "Noida",
    "Jalandhar",
    "Gurgaon",
  ]);
  const LocationSuggestions = [
    // Metro Cities
    { value: "Mumbai", label: "Mumbai" },
    { value: "Delhi", label: "Delhi" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Chennai", label: "Chennai" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Pune", label: "Pune" },
    // State Capitals
    { value: "Bhopal", label: "Bhopal" },
    { value: "Patna", label: "Patna" },
    { value: "Panaji", label: "Panaji" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Dispur", label: "Dispur" },
    { value: "Shimla", label: "Shimla" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Itanagar", label: "Itanagar" },
    { value: "Raipur", label: "Raipur" },
    { value: "Shillong", label: "Shillong" },
    { value: "Aizawl", label: "Aizawl" },
    { value: "Kohima", label: "Kohima" },
    { value: "Imphal", label: "Imphal" },
    { value: "Agartala", label: "Agartala" },
    { value: "Lucknow", label: "Lucknow" },
    // Union Territory Capitals
    { value: "Port Blair", label: "Port Blair" },
    { value: "Daman", label: "Daman" },
    { value: "Diu", label: "Diu" },
    { value: "Silvassa", label: "Silvassa" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Leh", label: "Leh" },
    // Popular Cities
    { value: "Varanasi", label: "Varanasi" },
    { value: "Agra", label: "Agra" },
    // Jaipur is duplicated, skipping the second one
    { value: "Udaipur", label: "Udaipur" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Kochi", label: "Kochi" },
    { value: "Mysore", label: "Mysore" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Surat", label: "Surat" },
    { value: "Indore", label: "Indore" },
    { value: "Nagpur", label: "Nagpur" },
    // Lucknow is duplicated, skipping the second one
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Madurai", label: "Madurai" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Nashik", label: "Nashik" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Meerut", label: "Meerut" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Jabalpur", label: "Jabalpur" },
    // Srinagar is duplicated, skipping the second one
    { value: "Thane", label: "Thane" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Amravati", label: "Amravati" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Noida", label: "Noida" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Gurgaon", label: "Gurgaon" },
  ];
  const RoleSuggestions = [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Accountant", label: "Accountant" },
    { value: "Sales Representative", label: "Sales Representative" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
  ];
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [totalExperience, setTotalExperience] = useState({
    year: "",
    month: "",
  });

  const experienceYears = [
    "Fresher",
    "0 Year(s)",
    "1 Year(s)",
    "2 Year(s)",
    "3 Year(s)",
    "4 Year(s)",
    "5 Year(s)",
    "6 Year(s)",
    "7 Year(s)",
    "8 Year(s)",
    "9 Year(s)",
    "10 Year(s)",
    // Add more years as needed...
  ];

  const experienceMonths = [
    "0 Month(s)",
    "1 Month(s)",
    "2 Month(s)",
    "3 Month(s)",
    "4 Month(s)",
    "5 Month(s)",
    "6 Month(s)",
    "7 Month(s)",
    "8 Month(s)",
    "9 Month(s)",
    "10 Month(s)",
    "11 Month(s)",
    // Add more months as needed...
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: value, // Dynamically update the right property based on the element's name
    }));
  };
  const handleChangeArray = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: [value], // Dynamically update the right property based on the element's name
    }));
  };
  // async function handleSumit() {
  //   // await mainContext.requestUpdateMyClaimLeads(mylead);
  //   // await mainContext.requestUpdateCandidateDetails(candidateDetail);
  //   console.log("Submit: ", candidateDetail);
  //   setSubmitState(true);
  //   // console.log("User: ", systemContext.user);
  //   const data = {
  //     uId: systemContext.user.user.employeeID,
  //     formData: candidateDetail,
  //   };
  //   const res = await systemContext.addCandidateFunction(data);
  //   setCandidateDetail({});
  // }
  async function handleSumit() {
    const { phoneNumber, name, currentLocation } = candidateDetail;

    if (!phoneNumber || !name || !currentLocation) {
      alert(
        "Please make sure that phone number, name, and current location are filled out."
      );
      return;
    }

    setSubmitState(true);

    const data = {
      uId: systemContext.user.user.employeeID,
      formData: candidateDetail,
    };
    const res = await systemContext.addCandidateFunction(data);
    document.getElementById("my_modal_1_add_by_form").close();
    // try {
    //   const res = await systemContext.addCandidateFunction(data);
    //   if (res) {
    //     console.log("Submission successful");
    //     setCandidateDetail({});
    //
    //   } else {
    //     alert("Submission failed. Please try again.");
    //   }
    // } catch (error) {
    //   alert(
    //     "An error occurred during submission. Please check your connection and try again."
    //   );
    // }
  }

  useEffect(() => {
    if (submitState) {
      handleDataUpdate();
    }
  }, [submitState]);
  useEffect(() => {
    console.log("Candidate Details: ", candidateDetail);
    // handleDataUpdate();
  }, [candidateDetail]);

  useEffect(() => {
    // Add totalExperience to the dependency array if its changes should also trigger this effect
    handleDataUpdate();
  }, [selectedLocations, selectedRole, totalExperience]); // Assuming totalExperience changes should reflect

  // async function handleDataUpdate() {
  //   // Map selectedLocations to only their values
  //   const preferredLocations = selectedLocations.map(
  //     (location) => location.value
  //   );

  //   // Similarly, map selectedRole to only their values
  //   const selectedRoles = selectedRole.map((role) => role.value);

  //   // Create the totalExperience string
  //   const totalExperienceString = `${totalExperience.year} ${totalExperience.month}`;

  //   // Set the date of application using moment with the Asia/Kolkata timezone
  //   const dateOfApplication = moment().tz("Asia/Kolkata").format("DD-MMM-YYYY");

  //   // Update candidateDetail with the new fields
  //   setCandidateDetail({
  //     ...candidateDetail,
  //     preferredLocations: preferredLocations,
  //     role: selectedRoles, // Ensure this field name is correct as per your application structure
  //     totalExperience: totalExperienceString, // Set the totalExperience string
  //     dateOfApplication: dateOfApplication, // Set the date of application
  //   });
  // }
  async function handleDataUpdate() {
    // Map selectedLocations to only their values
    const preferredLocations = selectedLocations.map(
      (location) => location.value
    );

    // Similarly, map selectedRole to only their values or assign an empty array if none selected
    const selectedRoles =
      selectedRole.length > 0 ? selectedRole.map((role) => role.value) : [];

    // Create the totalExperience string
    const totalExperienceString = `${totalExperience.year} ${totalExperience.month}`;

    // Set the date of application using moment with the Asia/Kolkata timezone
    const dateOfApplication = moment().tz("Asia/Kolkata").format("DD-MMM-YYYY");

    // Update candidateDetail with the new fields
    setCandidateDetail({
      ...candidateDetail,
      preferredLocations: preferredLocations,
      role: selectedRoles, // Ensure this field name is correct as per your application structure
      totalExperience: totalExperienceString, // Set the totalExperience string
      dateOfApplication: dateOfApplication, // Set the date of application
    });
  }

  return (
    <>
      <button
        // className="btn"
        onClick={() =>
          document.getElementById("my_modal_1_add_by_form").showModal()
        }
      >
        Add by Form
      </button>
      <dialog id="my_modal_1_add_by_form" className="modal">
        <div className="" style={{ padding: "25px" }}>
          <section
            className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800 lg:min-w-[1200px] p-8"
            style={{
              maxHeight: "90vh", // Maximum height is 100% of the viewport height
              overflowY: "auto", // Will only show scrollbar when necessary
            }}
          >
            {/* <div className="divider py-4"></div> */}
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
              Candidate Details
            </h2>
            <div className="my-4">
              <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1 ">
                {/* <div className="badge badge-lg  badge-outline">
                  Creation Time:{" "}
                  {candidateDetail
                    ? candidateDetail.profileCreationTime || systemContext.time
                    : "Loading..."}
                </div> */}
                {/* <br /> */}
                <div className="badge badge-lg  badge-outline">
                  Created By:{" "}
                  {candidateDetail
                    ? candidateDetail.profileCreatedById ||
                      systemContext.user.user.employeeID
                    : "Loading..."}
                </div>
              </h2>
              {/* <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white mb-1">
                    <div className="badge badge-lg  badge-outline">
                      Candidate Profile ID: {mylead.candidateProfileId}
                    </div>
                  </h2>
                  <div className="badge badge-lg badge-ghost">
                    {mylead.name}, {mylead.currentLocation}
                  </div>
                  <div className="badge badge-lg badge-ghost">
                    Contact : {mylead.phoneNumber}
                  </div> */}
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="username"
                >
                  Name
                </label>
                <input
                  name="name"
                  value={candidateDetail ? candidateDetail.name || "" : ""}
                  onChange={handleChange}
                  id="name"
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label className="text-gray-700 dark:text-gray-200" for="text">
                  Email ID
                </label>
                <input
                  id="emailId"
                  name="emailId"
                  value={candidateDetail ? candidateDetail.emailId || "" : ""}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="phoneNumber"
                >
                  {" "}
                  Contact Number
                </label>
                {/* <input
              name="phoneNumber"
              value={candidateDetail.phoneNumber}
              onChange={handleChange}
              id="phoneNumber"
              type="tel"
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            /> */}
                <input
                  name="phoneNumber"
                  value={
                    candidateDetail ? candidateDetail.phoneNumber || "" : ""
                  }
                  onChange={handleChange} // Use the provided handleChange function directly
                  id="phoneNumber"
                  type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
                  pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="alternativeNumber"
                >
                  {" "}
                  Alternative Phone Number
                </label>
                <input
                  name="alternativeNumber"
                  value={
                    candidateDetail
                      ? candidateDetail.alternativeNumber || ""
                      : ""
                  }
                  onChange={handleChange}
                  id="alternativeNumber"
                  type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
                  pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <p
                  className="text-gray-700 dark:text-gray-200 pb-2"
                  htmlFor="username"
                >
                  Current Location
                </p>

                <select
                  name="currentLocation" // Ensure this matches the state key
                  className="select select-bordered w-full max-w-xs"
                  value={
                    candidateDetail ? candidateDetail.currentLocation || "" : ""
                  }
                  onChange={handleChange} // Use the generic handleChange for all form elements
                  defaultValue=""
                  aria-label="Application Status"
                >
                  <option disabled value="">
                    Locations
                  </option>
                  {location.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="passwordConfirmation"
                >
                  Preferred Locations
                </label>
                <input
                  name="preferredLocations"
                  id="passwordConfirmation"
                  value={
                    candidateDetail
                      ? candidateDetail.preferredLocations || ""
                      : ""
                  }
                  //   value={candidateDetail.preferredLocations}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div> */}
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="passwordConfirmation"
                >
                  Preferred Locations
                </label>
                {/* <input
            id="passwordConfirmation"
            value={candidateDetail.preferredLocations}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
                {/* <MultiSelect
            options={LocationSuggestions}
            value={candidateDetail.preferredLocations}
            onChange={candidateDetail.preferredLocations}
            labelledBy="Select"
          /> */}
                <MultiSelect
                  className="py-2 mt-2 "
                  options={LocationSuggestions}
                  value={selectedLocations}
                  onChange={setSelectedLocations}
                  labelledBy="Select"
                />
              </div>
              <div>
                <p
                  className="text-gray-700 dark:text-gray-200 pb-2"
                  htmlFor="username"
                >
                  Gender
                </p>

                <select
                  name="gender" // Ensure this matches the state key
                  className="select select-bordered w-full max-w-xs"
                  value={candidateDetail ? candidateDetail.gender || "" : ""}
                  //   value={candidateDetail.gender}
                  onChange={handleChange} // Use the generic handleChange for all form elements
                  aria-label="Gender"
                >
                  <option disabled value="">
                    Gender Options
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="trans">Trans</option>
                </select>
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="maritalStatus"
                >
                  Marital Status
                </label>
                <input
                  name="maritalStatus"
                  id="maritalStatus"
                  value={
                    candidateDetail ? candidateDetail.maritalStatus || "" : ""
                  }
                  //   value={candidateDetail.maritalStatus}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="homeTownCity"
                >
                  Home Town City
                </label>
                <input
                  name="homeTownCity"
                  id="homeTownCity"
                  value={
                    candidateDetail ? candidateDetail.homeTownCity || "" : ""
                  }
                  //   value={candidateDetail.homeTownCity}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="pinCode"
                >
                  Pin Code
                </label>
                <input
                  name="pinCode"
                  id="pinCode"
                  value={candidateDetail ? candidateDetail.pinCode || "" : ""}
                  //   value={candidateDetail.pinCode}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="dateOfBirth"
                >
                  Date of Birth
                </label>
                <input
                  name="dateOfBirth"
                  id="dateOfBirth"
                  value={
                    candidateDetail ? candidateDetail.dateOfBirth || "" : ""
                  }
                  //   value={candidateDetail.dateOfBirth}
                  onChange={handleChange}
                  type="date"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="permanentAddress"
                >
                  Permanent Address
                </label>
                <input
                  name="permanentAddress"
                  id="permanentAddress"
                  value={
                    candidateDetail
                      ? candidateDetail.permanentAddress || ""
                      : ""
                  }
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              {/* <div>
                <label className="text-gray-700 dark:text-gray-200" for="text">
                  Total Experience
                </label>
                <input
                  name="totalExperience"
                  id="totalExperience"
                  value={
                    candidateDetail ? candidateDetail.totalExperience || "" : ""
                  }
                  //   value={candidateDetail.totalExperience}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div> */}
              <div>
                <label className="text-gray-700 dark:text-gray-200" for="text">
                  Total Experience
                </label>
                <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <select
                    name="currentLocation" // Ensure this matches the state key
                    className="select select-bordered w-full max-w-xs"
                    value={totalExperience.year}
                    // onChange={handleChange} // Use the generic handleChange for all form elements
                    onChange={(event) => {
                      // Update the year in totalExperience while keeping the month unchanged
                      setTotalExperience({
                        ...totalExperience,
                        year: event.target.value,
                      });
                    }}
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Years
                    </option>
                    {experienceYears.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    name="currentLocation" // Ensure this matches the state key
                    className="select select-bordered w-full max-w-xs"
                    value={totalExperience.month}
                    // onChange={handleChange} // Use the generic handleChange for all form elements
                    onChange={(event) => {
                      // Update the year in totalExperience while keeping the month unchanged
                      setTotalExperience({
                        ...totalExperience,
                        month: event.target.value,
                      });
                    }}
                    defaultValue=""
                    aria-label="Application Status"
                  >
                    <option disabled value="">
                      Months
                    </option>
                    {experienceMonths.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="currCompanyName"
                >
                  Current Company Name
                </label>
                <input
                  name="currCompanyName"
                  id="currCompanyName"
                  value={
                    candidateDetail ? candidateDetail.currCompanyName || "" : ""
                  }
                  //   value={candidateDetail.currCompanyName}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="currCompanyDesignation"
                >
                  Current Company Designation
                </label>
                <input
                  name="currCompanyDesignation"
                  id="currCompanyDesignation"
                  value={
                    candidateDetail
                      ? candidateDetail.currCompanyDesignation || ""
                      : ""
                  }
                  //   value={candidateDetail.currCompanyDesignation}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="department"
                >
                  Current Department
                </label>
                <input
                  name="department"
                  id="department"
                  value={
                    candidateDetail ? candidateDetail.department || "" : ""
                  }
                  //   value={candidateDetail.department}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-gray-700 dark:text-gray-200" for="role">
                  Role
                </label>
                {/* <input
                  name="role"
                  id="role"
                  value={candidateDetail ? candidateDetail.role || "" : ""}
                  //   value={candidateDetail.role}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                /> */}
                <MultiSelect
                  className="py-2  "
                  options={RoleSuggestions}
                  value={selectedRole}
                  onChange={setSelectedRole}
                  labelledBy="Select"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="industry"
                >
                  Current Industry
                </label>
                <input
                  name="industry"
                  id="industry"
                  value={candidateDetail ? candidateDetail.industry || "" : ""}
                  //   value={candidateDetail.industry}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="keySkills"
                >
                  Key Skills
                </label>
                <input
                  name="keySkills"
                  id="keySkills"
                  value={candidateDetail ? candidateDetail.keySkills || "" : ""}
                  //   value={candidateDetail.keySkills}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="annualSalary"
                >
                  Annual Salary
                </label>
                <input
                  name="annualSalary"
                  id="annualSalary"
                  value={
                    candidateDetail ? candidateDetail.annualSalary || "" : ""
                  }
                  //   value={candidateDetail.annualSalary}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="noticePeriodAvailabilityToJoin"
                >
                  Notice Period Availability To Join
                </label>
                <input
                  name="noticePeriodAvailabilityToJoin"
                  id="noticePeriodAvailabilityToJoin"
                  value={
                    candidateDetail
                      ? candidateDetail.noticePeriodAvailabilityToJoin || ""
                      : ""
                  }
                  //   value={candidateDetail.noticePeriodAvailabilityToJoin}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="resumeHeadline"
                >
                  Resume Headline
                </label>
                <input
                  name="resumeHeadline"
                  id="resumeHeadline"
                  value={
                    candidateDetail ? candidateDetail.resumeHeadline || "" : ""
                  }
                  //   value={candidateDetail.resumeHeadline}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <p
                  className="text-gray-700 dark:text-gray-200 my-2"
                  for="resumeSummary"
                >
                  Notes
                </p>
                <textarea
                  name="resumeSummary"
                  id="resumeSummary"
                  value={
                    candidateDetail ? candidateDetail.resumeSummary || "" : ""
                  }
                  //   value={candidateDetail.resumeSummary}
                  onChange={handleChange}
                  className="textarea textarea-bordered w-full"
                  placeholder="Add Additional Notes Here"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="underGraduationDegree"
                >
                  UG Graduation Degree
                </label>
                <input
                  name="underGraduationDegree"
                  id="underGraduationDegree"
                  value={
                    candidateDetail
                      ? candidateDetail.underGraduationDegree || ""
                      : ""
                  }
                  //   value={candidateDetail.underGraduationDegree}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="underGraduationDegree"
                >
                  UG Specialization
                </label>
                <input
                  name="ugSpecialization"
                  id="ugSpecialization"
                  value={
                    candidateDetail
                      ? candidateDetail.ugSpecialization || ""
                      : ""
                  }
                  //   value={candidateDetail.ugSpecialization}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="ugUniversityInstituteName"
                >
                  UG University Institute Name
                </label>
                <input
                  name="ugUniversityInstituteName"
                  id="ugUniversityInstituteName"
                  value={
                    candidateDetail
                      ? candidateDetail.ugUniversityInstituteName || ""
                      : ""
                  }
                  //   value={candidateDetail.ugUniversityInstituteName}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="ugGraduationYear"
                >
                  UG Graduation Year
                </label>
                <input
                  name="ugGraduationYear"
                  id="ugGraduationYear"
                  value={
                    candidateDetail
                      ? candidateDetail.ugGraduationYear || ""
                      : ""
                  }
                  //   value={candidateDetail.ugGraduationYear}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="postGraduationDegree"
                >
                  Post Graduation Degree
                </label>
                <input
                  name="postGraduationDegree"
                  id="postGraduationDegree"
                  value={
                    candidateDetail
                      ? candidateDetail.postGraduationDegree || ""
                      : ""
                  }
                  //   value={candidateDetail.postGraduationDegree}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="pgSpecialization"
                >
                  PG Specialization
                </label>
                <input
                  name="pgSpecialization"
                  id="pgSpecialization"
                  value={
                    candidateDetail
                      ? candidateDetail.pgSpecialization || ""
                      : ""
                  }
                  //   value={candidateDetail.pgSpecialization}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="pgUniversityInstituteName"
                >
                  PG University Institute Name
                </label>
                <input
                  name="pgUniversityInstituteName"
                  id="pgUniversityInstituteName"
                  value={
                    candidateDetail
                      ? candidateDetail.pgUniversityInstituteName || ""
                      : ""
                  }
                  //   value={candidateDetail.pgUniversityInstituteName}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="pgGraduationYear"
                >
                  PG Graduation Year
                </label>
                <input
                  name="pgGraduationYear"
                  id="pgGraduationYear"
                  value={
                    candidateDetail
                      ? candidateDetail.pgGraduationYear || ""
                      : ""
                  }
                  //   value={candidateDetail.pgGraduationYear}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="doctorateDegree"
                >
                  Doctorate Degree
                </label>
                <input
                  name="doctorateDegree"
                  id="doctorateDegree"
                  value={
                    candidateDetail ? candidateDetail.doctorateDegree || "" : ""
                  }
                  //   value={candidateDetail.doctorateDegree}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="doctorateSpecialization"
                >
                  Doctorate Specialization
                </label>
                <input
                  name="doctorateSpecialization"
                  id="doctorateSpecialization"
                  value={
                    candidateDetail
                      ? candidateDetail.doctorateSpecialization || ""
                      : ""
                  }
                  //   value={candidateDetail.doctorateSpecialization}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="doctorateUniversityInstituteName"
                >
                  Doctorate University Institute Name
                </label>
                <input
                  name="doctorateUniversityInstituteName"
                  id="doctorateUniversityInstituteName"
                  value={
                    candidateDetail
                      ? candidateDetail.doctorateUniversityInstituteName || ""
                      : ""
                  }
                  //   value={candidateDetail.doctorateUniversityInstituteName}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="doctorateGraduationYear"
                >
                  Doctorate Graduation Year
                </label>
                <input
                  name="doctorateGraduationYear"
                  id="doctorateSpecialization"
                  value={
                    candidateDetail
                      ? candidateDetail.doctorateGraduationYear || ""
                      : ""
                  }
                  //   value={candidateDetail.doctorateGraduationYear}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  for="workPermitForUsa"
                >
                  Work Permit For Usa
                </label>
                <input
                  name="workPermitForUsa"
                  id="workPermitForUsa"
                  value={
                    candidateDetail
                      ? candidateDetail.workPermitForUsa || ""
                      : ""
                  }
                  //   value={candidateDetail.workPermitForUsa}
                  onChange={handleChange}
                  type="text"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
            </div>
            <div className="modal-action pt-4">
              <button
                className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                onClick={() => {
                  handleSumit();
                }}
              >
                Save
              </button>
              <form method="dialog">
                <button
                  className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600"
                  onClick={() => {
                    setCandidateDetail({});
                  }}
                >
                  Cancel
                </button>
              </form>
            </div>
          </section>
        </div>
      </dialog>
    </>
  );
}
