import react from "@heroicons/react";

export default function ProfileSettings() {
  async function handleRest() {
    // Clear local storage
    localStorage.clear();

    // Clear session storage
    sessionStorage.clear();

    // Clear all cookies
    document.cookie.split(";").forEach(function (c) {
      document.cookie =
        c.trim().split("=")[0] +
        "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });

    // Clear Cache API (if service workers are used)
    if ("caches" in window) {
      caches.keys().then((keyList) => {
        return Promise.all(keyList.map((key) => caches.delete(key)));
      });
    }

    // Clear IndexedDB (asynchronously)
    if (window.indexedDB) {
      window.indexedDB.databases().then(function (databases) {
        databases.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
      });
    }
  }
  return (
    <>
      <>
        <div className="min-h-full">
          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                Profile Settings
              </h1>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <button
                class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                onClick={() => {
                  handleRest();
                }}
              >
                Reset
              </button>
            </div>
          </main>
        </div>
      </>
    </>
  );
}
