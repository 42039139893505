import React from "react";
// import Filters from "./liveCandidateComponents/Filters";

export default function CRMSettingsNavbar({
  selectedTab,
  setSelectedTab,
  allTabs,
}) {
  // Check if the list is empty
  if (!allTabs || allTabs.length === 0) {
    return <p>No items to display.</p>; // Or any other placeholder you'd like to show
  }

  // Function to handle tab click
  const handleTabClick = (path) => {
    setSelectedTab(path); // Update the selected tab state
  };
  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {/* <li className="me-2">
            <a
              href="#"
              className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            >
              Profile
            </a>
          </li>
          <li className="me-2">
            <a
              href="#"
              className="inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
              aria-current="page"
            >
              Dashboard
            </a>
          </li>
          <li className="me-2">
            <a
              href="#"
              className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            >
              Settings
            </a>
          </li>
          <li className="me-2">
            <a
              href="#"
              className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            >
              Contacts
            </a>
          </li>
          <li>
            <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">
              Disabled
            </a>
          </li> */}

          {/* text-blue-600 */}
          {allTabs.map((item, index) => (
            <li key={index} className="me-2">
              <a
                href="#"
                onClick={() => handleTabClick(item.path)} // Set the selected tab on click
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  selectedTab === item.path
                    ? "text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500" // Selected tab styles
                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" // Not selected tab styles
                }`}
              >
                {item.name}{" "}
                {/* Assuming each item in the list has a 'name' and 'path' property */}
              </a>
            </li>
          ))}
          {/* <li key="filter" className="me-2">
            <Filters />
          </li> */}
        </ul>
      </div>
    </>
  );
}
