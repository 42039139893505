import React, { useEffect, useState } from "react";
import FileUploadHandle from "./FileUploadHandler/FileUploadHandle";

// Import Context API
import { useContext } from "react";
import SystemContext from "../../ContextAPI/SystemContext/SystemContext";

export default function AddNewLeads() {
  const systemContext = useContext(SystemContext);
  const [windowState, setWindowState] = useState(false);
  const [process, setProcess] = useState(false);
  const [fileInstance, setFileInstance] = useState(null);
  const [fileMetadata, setFileMetadata] = useState({
    name: "",
    size: 0,
    sizeUnit: "",
    lastModifiedDate: "",
    lastModifiedTime: "",
    type: "",
    extension: "",
  });
  useEffect(() => {
    async function run() {
      await systemContext.uploadLeadData(fileInstance);
    }
    run();
  }, [process]);
  return (
    <>
      <>
        {/* You can open the modal using document.getElementById('ID').showModal() method */}
        {/* <button
          className="btn"
          onClick={() => document.getElementById("my_modal_4").showModal()}
        >
          open modal
        </button> */}
        <button
          className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
          onClick={() => document.getElementById("my_modal_4").showModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span>Add New Leads</span>
        </button>
        <dialog id="my_modal_4" className="modal">
          <div className="modal-box w-11/12 max-w-5xl w-100">
            <h3 className="font-bold text-lg">Upload New Leads</h3>
            <FileUploadHandle
              windowState={windowState}
              process={process}
              fileMetadata={fileMetadata}
              setFileMetadata={setFileMetadata}
              fileInstance={fileInstance}
              setFileInstance={setFileInstance}
            />

            {/* <p className="py-4">Click the button below to close</p> */}
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button, it will close the modal */}
                {/* <button className="btn">Save</button> */}
                {/* <button
                  className="btn mx-2 bg-blue-600 "
                  onClick={() => {
                    setProcess((prevWindowState) => !prevWindowState);
                  }}
                ></button> */}
                {fileMetadata.name !== "" && (
                  <button
                    className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                    onClick={() => {
                      // setProcess((prevWindowState) => !prevWindowState);
                      setProcess(true);
                      console.log("Upload Clicked");
                    }}
                  >
                    Upload
                  </button>
                )}

                <button
                  //   className="btn mx-2"
                  className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                  onClick={() => {
                    setWindowState((prevWindowState) => !prevWindowState);
                  }}
                >
                  Close
                </button>
              </form>
            </div>
          </div>
        </dialog>
      </>
    </>
  );
}
