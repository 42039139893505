import axios from "axios";

export async function GetAuth(Host, loginID, password) {
  try {
    console.log("base ", Host, loginID, password);
    const response = await axios.post(`${Host}/api/auth/login`, {
      employeeID: loginID,
      password: password,
    });

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
