import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ReactTags } from "react-tag-autocomplete";
import { MultiSelect } from "react-multi-select-component";

// Import Context API
import { useContext } from "react";
import MainContext from "../ContextAPI/MainContext/MainContext";
export default function ExternalCandidate() {
  const mainContext = useContext(MainContext);
  const [errorStatus, setErrorStatus] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [candidateDetail, setCandidateDetail] = useState({
    name: "",
    emailId: "",
    phoneNumber: "",
    alternativeNumber: "",
    currentLocation: "",
    preferred: "",
    // Initialize all the keys for candidateDetail here
    // Add more keys as needed
  });

  const [location, setLocation] = useState([
    // Metro Cities
    "Mumbai",
    "Delhi",
    "Kolkata",
    "Chennai",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    // State Capitals
    "Bhopal",
    "Patna",
    "Panaji",
    "Gandhinagar",
    "Chandigarh",
    "Ranchi",
    "Thiruvananthapuram",
    "Bhubaneswar",
    "Dehradun",
    "Dispur",
    "Shimla",
    "Srinagar",
    "Jaipur",
    "Gangtok",
    "Itanagar",
    "Raipur",
    "Shillong",
    "Aizawl",
    "Kohima",
    "Imphal",
    "Agartala",
    "Lucknow",
    // Union Territory Capitals
    "Port Blair",
    "Daman",
    "Diu",
    "Silvassa",
    "Puducherry",
    "Leh",
    // Popular Cities
    "Varanasi",
    "Agra",
    "Jaipur",
    "Udaipur",
    "Amritsar",
    "Kochi",
    "Mysore",
    "Visakhapatnam",
    "Surat",
    "Indore",
    "Nagpur",
    "Lucknow",
    "Coimbatore",
    "Madurai",
    "Ludhiana",
    "Kanpur",
    "Nashik",
    "Vadodara",
    "Rajkot",
    "Meerut",
    "Faridabad",
    "Ghaziabad",
    "Rajahmundry",
    "Jabalpur",
    "Srinagar",
    "Thane",
    "Aurangabad",
    "Dhanbad",
    "Amravati",
    "Kolhapur",
    "Ajmer",
    "Tiruchirappalli",
    "Jodhpur",
    "Gwalior",
    "Noida",
    "Jalandhar",
    "Gurgaon",
  ]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [totalExperience, setTotalExperience] = useState({
    year: "",
    month: "",
  });

  // useEffect(() => {
  //   console.log("Locations: ", selectedLocations);
  // }, [selectedLocations]);

  useEffect(() => {
    console.log("Candidate Details Updated: ", candidateDetail);
  }, [candidateDetail]);

  // useEffect(() => {
  //   // Map selectedLocations to only their values
  //   const preferredLocations = selectedLocations.map(
  //     (location) => location.value
  //   );

  //   // Similarly, map selectedRole to only their values
  //   const selectedRoles = selectedRole.map((role) => role.value);

  //   // Assuming candidateDetail is a state you want to update
  //   setCandidateDetail({
  //     ...candidateDetail,
  //     preferredLocations: preferredLocations,
  //     role: selectedRoles, // Assuming you want to store this in a property called 'roles'
  //   });

  //   // If candidateDetail is not a state or you want to handle the update differently,
  //   // you might need to adjust the way you're setting preferredLocations and roles.
  // }, [selectedLocations, selectedRole]); // Make sure to include selectedRole in the dependency array

  useEffect(() => {
    // Map selectedLocations to only their values
    const preferredLocations = selectedLocations.map(
      (location) => location.value
    );

    // Similarly, map selectedRole to only their values
    const selectedRoles = selectedRole.map((role) => role.value);

    // Create the totalExperience string
    const totalExperienceString = `${totalExperience.year} ${totalExperience.month}`;

    // Update candidateDetail with the new fields
    setCandidateDetail({
      ...candidateDetail,
      preferredLocations: preferredLocations,
      role: selectedRoles, // Note: Changed to 'role' to match your comment, ensure this is correct
      totalExperience: totalExperienceString, // Set the totalExperience string
    });

    // Add totalExperience to the dependency array if its changes should also trigger this effect
  }, [selectedLocations, selectedRole, totalExperience]); // Assuming totalExperience changes should reflect

  const handleSubmit = async () => {
    console.log("Submit");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token"); // Assuming 'token' is the correct parameter name

    console.log("Token:", token);

    try {
      // Await the response from addCandidateByLinkFunction
      const response = await mainContext.addCandidateByLinkFunction(
        candidateDetail,
        token
      );

      // Check if the response status indicates success (e.g., status code 200)
      if (response.status === 200) {
        // Set success status true, error status false
        setSuccessStatus(true);
        setErrorStatus(false);
        console.log("Submission successful");
      } else {
        // If the response status is not 200, consider it a failure
        setSuccessStatus(false);
        setErrorStatus(true);
        console.log("Submission failed with status:", response.status);
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      setSuccessStatus(false);
      setErrorStatus(true);
      console.error("Submission failed with error:", error);
    }
  };

  const handleRefresh = () => {
    console.log("Refresh");
  };

  return (
    <div>
      <div className="navbar bg-neutral text-neutral-content">
        <button className="btn btn-ghost text-xl">HirewWynk Jobs</button>
      </div>
      {Carousel2()}

      {!successStatus && !errorStatus && (
        <>
          {candidateProfileDisplay(
            candidateDetail,
            setCandidateDetail,
            location,

            selectedLocations,
            setSelectedLocations,
            handleSubmit,
            handleRefresh,
            selectedRole,
            setSelectedRole,
            totalExperience,
            setTotalExperience
          )}
        </>
      )}
      {successStatus && statusSuccessDisplay(candidateDetail)}
      {errorStatus && statusErrorsDisplay(candidateDetail)}

      {!successStatus && !errorStatus && <> {testimonials()}</>}
      {footer()}
    </div>
  );
}
function statusSuccessDisplay(candidateDetail) {
  return (
    <>
      <section class="bg-white dark:bg-gray-900">
        <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
          <h2 class="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
            {candidateDetail.name}, You Have Successfully Submitted,
          </h2>
          <h2 class="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
            <span class="text-blue-500">Thank You for Your Application!.</span>
          </h2>

          <p class="max-w-4xl mt-6 text-center text-gray-500 dark:text-gray-300">
            Our team will review the submission promptly, and we look forward to
            exploring the potential for collaboration. Thank you,{" "}
            {candidateDetail.name}, for showing your interest. We will be in
            touch with the next steps soon!
          </p>

          <div class="inline-flex w-full mt-6 sm:w-auto">
            {/* <a
              href="#"
              class="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            >
              Sign Up
            </a> */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg> */}
          </div>
        </div>
      </section>
    </>
  );
}
function statusErrorsDisplay(candidateDetail) {
  return (
    <>
      <section class="bg-white dark:bg-gray-900">
        <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
          <h2 class="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
            <span class="text-red-500">
              Submission Unsuccessful for {candidateDetail.name}!
            </span>
          </h2>

          <p class="max-w-4xl mt-6 text-center text-gray-500 dark:text-gray-300">
            We regret to inform you that the submission of{" "}
            {candidateDetail.name}
            application details was unsuccessful due to technical difficulties.
            We sincerely apologize for the inconvenience and urge you to contact
            us directly to resolve this issue. Our team is ready to assist you
            to ensure your application is processed promptly. Please reach out
            to us at your earliest convenience. Thank you for your patience and
            understanding.
          </p>

          <div class="inline-flex w-full mt-6 sm:w-auto">
            {/* <a
              href="#"
              class="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            >
              Sign Up
            </a> */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg> */}
          </div>
        </div>
      </section>
    </>
  );
}
function candidateProfileDisplay(
  candidateDetail,
  setCandidateDetail,
  location,

  selectedLocations,
  setSelectedLocations,
  handleSubmit,
  handleRefresh,
  selectedRole,
  setSelectedRole,
  totalExperience,
  setTotalExperience
  // LocationSuggestions
) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: value, // Dynamically update the right property based on the element's name
    }));
  };
  const handleChangeArray = (e) => {
    const { name, value } = e.target;
    setCandidateDetail((prevLead) => ({
      ...prevLead,
      [name]: [value], // Dynamically update the right property based on the element's name
    }));
  };

  if (!candidateDetail) {
    return <div>Loading...</div>;
  }
  const LocationSuggestions = [
    // Metro Cities
    { value: "Mumbai", label: "Mumbai" },
    { value: "Delhi", label: "Delhi" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Chennai", label: "Chennai" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Pune", label: "Pune" },
    // State Capitals
    { value: "Bhopal", label: "Bhopal" },
    { value: "Patna", label: "Patna" },
    { value: "Panaji", label: "Panaji" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Dispur", label: "Dispur" },
    { value: "Shimla", label: "Shimla" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Itanagar", label: "Itanagar" },
    { value: "Raipur", label: "Raipur" },
    { value: "Shillong", label: "Shillong" },
    { value: "Aizawl", label: "Aizawl" },
    { value: "Kohima", label: "Kohima" },
    { value: "Imphal", label: "Imphal" },
    { value: "Agartala", label: "Agartala" },
    { value: "Lucknow", label: "Lucknow" },
    // Union Territory Capitals
    { value: "Port Blair", label: "Port Blair" },
    { value: "Daman", label: "Daman" },
    { value: "Diu", label: "Diu" },
    { value: "Silvassa", label: "Silvassa" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Leh", label: "Leh" },
    // Popular Cities
    { value: "Varanasi", label: "Varanasi" },
    { value: "Agra", label: "Agra" },
    // Jaipur is duplicated, skipping the second one
    { value: "Udaipur", label: "Udaipur" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Kochi", label: "Kochi" },
    { value: "Mysore", label: "Mysore" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Surat", label: "Surat" },
    { value: "Indore", label: "Indore" },
    { value: "Nagpur", label: "Nagpur" },
    // Lucknow is duplicated, skipping the second one
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Madurai", label: "Madurai" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Nashik", label: "Nashik" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Meerut", label: "Meerut" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Jabalpur", label: "Jabalpur" },
    // Srinagar is duplicated, skipping the second one
    { value: "Thane", label: "Thane" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Amravati", label: "Amravati" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Noida", label: "Noida" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Gurgaon", label: "Gurgaon" },
  ];
  const RoleSuggestions = [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Accountant", label: "Accountant" },
    { value: "Sales Representative", label: "Sales Representative" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
  ];
  // const experienceYears = [
  //   { value: "0 Year(s)", label: "0 Year(s)" },
  //   { value: "1 Year(s)", label: "1 Year(s)" },
  //   { value: "2 Year(s)", label: "2 Year(s)" },
  //   { value: "3 Year(s)", label: "3 Year(s)" },
  //   { value: "4 Year(s)", label: "4 Year(s)" },
  //   { value: "5 Year(s)", label: "5 Year(s)" },
  //   { value: "6 Year(s)", label: "6 Year(s)" },
  //   { value: "7 Year(s)", label: "7 Year(s)" },
  //   { value: "8 Year(s)", label: "8 Year(s)" },
  //   { value: "9 Year(s)", label: "9 Year(s)" },
  //   { value: "10 Year(s)", label: "10 Year(s)" },
  //   // Add more years as needed...
  // ];

  // const experienceYMonths = [
  //   { value: "0 Month(s)", label: "0 Month(s)" },
  //   { value: "1 Month(s)", label: "1 Month(s)" },
  //   { value: "2 Month(s)", label: "2 Month(s)" },
  //   { value: "3 Month(s)", label: "3 Month(s)" },
  //   { value: "4 Month(s)", label: "4 Month(s)" },
  //   { value: "5 Month(s)", label: "5 Month(s)" },
  //   { value: "6 Month(s)", label: "6 Month(s)" },
  //   { value: "7 Month(s)", label: "7 Month(s)" },
  //   { value: "8 Month(s)", label: "8 Month(s)" },
  //   { value: "9 Month(s)", label: "9 Month(s)" },
  //   { value: "10 Month(s)", label: "10 Month(s)" },
  //   { value: "11 Month(s)", label: "11 Month(s)" },
  //   // Add more months as needed...
  // ];

  const experienceYears = [
    "Fresher",
    "0 Year(s)",
    "1 Year(s)",
    "2 Year(s)",
    "3 Year(s)",
    "4 Year(s)",
    "5 Year(s)",
    "6 Year(s)",
    "7 Year(s)",
    "8 Year(s)",
    "9 Year(s)",
    "10 Year(s)",
    // Add more years as needed...
  ];

  const experienceMonths = [
    "0 Month(s)",
    "1 Month(s)",
    "2 Month(s)",
    "3 Month(s)",
    "4 Month(s)",
    "5 Month(s)",
    "6 Month(s)",
    "7 Month(s)",
    "8 Month(s)",
    "9 Month(s)",
    "10 Month(s)",
    "11 Month(s)",
    // Add more months as needed...
  ];

  return (
    // <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
    <div class=" px-8 py-6 bg-white rounded-lg shadow-md dark:bg-gray-800 mx-2 my-6">
      <div class="mt-2 my-4">
        <a className="text-xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline">
          Thanks for showing your interest
        </a>
        <p class="mt-2 text-gray-600 dark:text-gray-300">
          Please fill out the form below with your details
          {/* and attach your resume.  */}
          We're looking for individuals who are passionate, driven, and ready to
          contribute to our mission. Take the first step towards an exciting
          career opportunity!
        </p>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="username">
            Name
          </label>
          <input
            name="name"
            value={candidateDetail.name || ""}
            onChange={handleChange}
            id="name"
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="text">
            Email ID
          </label>
          <input
            id="emailId"
            value={candidateDetail.emailId}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="phoneNumber">
            {" "}
            Contact Number
          </label>
          {/* <input
              name="phoneNumber"
              value={candidateDetail.phoneNumber}
              onChange={handleChange}
              id="phoneNumber"
              type="tel"
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            /> */}
          <input
            name="phoneNumber"
            value={candidateDetail.phoneNumber}
            onChange={handleChange} // Use the provided handleChange function directly
            id="phoneNumber"
            type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
            pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="alternativeNumber"
          >
            {" "}
            Alternative Phone Number
          </label>
          <input
            name="alternativeNumber"
            value={candidateDetail.alternativeNumber}
            onChange={handleChange}
            id="alternativeNumber"
            type="tel" // Use 'tel' to bring up a telephone keypad on mobile devices
            pattern="\d*" // Optional: Ensures only digits can be entered, if strictly numeric input is desired
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <p
            className="text-gray-700 dark:text-gray-200 pb-2"
            htmlFor="currentLocation"
          >
            Current Location
          </p>

          <select
            name="currentLocation" // Ensure this matches the state key
            className="select select-bordered w-full max-w-xs"
            value={candidateDetail.currentLocation}
            onChange={handleChange} // Use the generic handleChange for all form elements
            defaultValue=""
            aria-label="Application Status"
          >
            <option disabled value="">
              Locations
            </option>
            {location.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="passwordConfirmation"
          >
            Preferred Locations
          </label>
          {/* <input
            id="passwordConfirmation"
            value={candidateDetail.preferredLocations}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
          {/* <MultiSelect
            options={LocationSuggestions}
            value={candidateDetail.preferredLocations}
            onChange={candidateDetail.preferredLocations}
            labelledBy="Select"
          /> */}
          <MultiSelect
            className="py-2 mt-2 "
            options={LocationSuggestions}
            value={selectedLocations}
            onChange={setSelectedLocations}
            labelledBy="Select"
          />
        </div>

        <div>
          <p
            className="text-gray-700 dark:text-gray-200 pb-2"
            htmlFor="username"
          >
            Gender
          </p>

          <select
            name="gender" // Ensure this matches the state key
            className="select select-bordered w-full max-w-xs"
            value={candidateDetail.gender}
            onChange={handleChange} // Use the generic handleChange for all form elements
            aria-label="Gender"
          >
            <option disabled value="">
              Gender Options
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="trans">Trans</option>
          </select>
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="maritalStatus"
          >
            Marital Status
          </label>
          <input
            id="maritalStatus"
            value={candidateDetail.maritalStatus}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="homeTownCity"
          >
            Home Town City
          </label>
          <input
            id="homeTownCity"
            value={candidateDetail.homeTownCity}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        {/* <div>
            <p
              className="text-gray-700 dark:text-gray-200 pb-2"
              htmlFor="username"
            >
              Selected Location
            </p>
  
            <select
              className="select select-bordered w-full max-w-xs"
              value={candidateDetail.homeTownCity}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Locations
              </option>
              {location.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div> */}
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="pinCode">
            Pin Code
          </label>
          <input
            id="pinCode"
            value={candidateDetail.pinCode}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="dateOfBirth">
            Date of Birth
          </label>
          <input
            id="dateOfBirth"
            value={candidateDetail.dateOfBirth}
            handleChange={handleChange}
            type="date"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="permanentAddress"
          >
            Permanent Address
          </label>
          <input
            id="permanentAddress"
            value={candidateDetail.permanentAddress}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label className="text-gray-700 dark:text-gray-200" for="text">
            Total Experience
          </label>
          {/* totalExperience, setTotalExperience */}
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={totalExperience.year}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setTotalExperience({
                  ...totalExperience,
                  year: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Years
              </option>
              {experienceYears.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={totalExperience.month}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setTotalExperience({
                  ...totalExperience,
                  month: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Months
              </option>
              {experienceMonths.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* <input
            id="password"
            value={candidateDetail.totalExperience}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="currCompanyName"
          >
            Current Company Name
          </label>
          <input
            id="currCompanyName"
            value={candidateDetail.currCompanyName}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="currCompanyDesignation"
          >
            Current Company Designation
          </label>
          <input
            id="currCompanyDesignation"
            value={candidateDetail.currCompanyDesignation}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="passwordConfirmation"
          >
            Current Department
          </label>
          <input
            id="passwordConfirmation"
            value={candidateDetail.department}
            onChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="role">
            Role
          </label>
          {/* <input
            id="role"
            value={candidateDetail.role}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}

          <MultiSelect
            className="py-2  "
            options={RoleSuggestions}
            value={selectedRole}
            onChange={setSelectedRole}
            labelledBy="Select"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="industry">
            Current Industry
          </label>
          <input
            id="industry"
            value={candidateDetail.industry}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label className="text-gray-700 dark:text-gray-200" for="keySkills">
            Key Skills
          </label>
          <input
            id="keySkills"
            value={candidateDetail.keySkills}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="annualSalary"
          >
            Annual Salary
          </label>
          <input
            id="annualSalary"
            value={candidateDetail.annualSalary}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="noticePeriodAvailabilityToJoin"
          >
            Notice Period Availability To Join
          </label>
          <input
            id="noticePeriodAvailabilityToJoin"
            value={candidateDetail.noticePeriodAvailabilityToJoin}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="resumeHeadline"
          >
            Resume Headline
          </label>
          <input
            id="resumeHeadline"
            value={candidateDetail.resumeHeadline}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <p className="text-gray-700 dark:text-gray-200 my-2" for="username">
            Notes
          </p>
          <textarea
            id="resumeSummary"
            value={candidateDetail.resumeSummary}
            handleChange={handleChange}
            className="textarea textarea-bordered w-full"
            placeholder="Add Additional Notes Here"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-3">
        {/* <div>
            <label
              className="text-gray-700 dark:text-gray-200"
              for="resumeSummary"
            >
              resumeSummary
            </label>
            <input
              id="resumeSummary"
              value={candidateDetail.resumeSummary}
              handleChange={handleChange}
              type="text"
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div> */}
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="underGraduationDegree"
          >
            UG Graduation Degree
          </label>
          <input
            id="underGraduationDegree"
            value={candidateDetail.underGraduationDegree}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="underGraduationDegree"
          >
            UG Specialization
          </label>
          <input
            id="ugSpecialization"
            value={candidateDetail.ugSpecialization}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="ugUniversityInstituteName"
          >
            UG University Institute Name
          </label>
          <input
            id="ugUniversityInstituteName"
            value={candidateDetail.ugUniversityInstituteName}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="ugGraduationYear"
          >
            UG Graduation Year
          </label>
          <input
            id="ugGraduationYear"
            value={candidateDetail.ugGraduationYear}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="postGraduationDegree"
          >
            Post Graduation Degree
          </label>
          <input
            id="postGraduationDegree"
            value={candidateDetail.postGraduationDegree}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgSpecialization"
          >
            PG Specialization
          </label>
          <input
            id="pgSpecialization"
            value={candidateDetail.pgSpecialization}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgUniversityInstituteName"
          >
            PG University Institute Name
          </label>
          <input
            id="pgUniversityInstituteName"
            value={candidateDetail.pgUniversityInstituteName}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="pgGraduationYear"
          >
            PG Graduation Year
          </label>
          <input
            id="pgGraduationYear"
            value={candidateDetail.pgGraduationYear}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateDegree"
          >
            Doctorate Degree
          </label>
          <input
            id="doctorateDegree"
            value={candidateDetail.doctorateDegree}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateSpecialization"
          >
            Doctorate Specialization
          </label>
          <input
            id="doctorateSpecialization"
            value={candidateDetail.doctorateSpecialization}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateUniversityInstituteName"
          >
            Doctorate University Institute Name
          </label>
          <input
            id="doctorateUniversityInstituteName"
            value={candidateDetail.doctorateUniversityInstituteName}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="doctorateGraduationYear"
          >
            Doctorate Graduation Year
          </label>
          <input
            id="doctorateSpecialization"
            value={candidateDetail.doctorateGraduationYear}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>

        <div>
          <label
            className="text-gray-700 dark:text-gray-200"
            for="workPermitForUsa"
          >
            Work Permit For Usa
          </label>
          <input
            id="workPermitForUsa"
            value={candidateDetail.workPermitForUsa}
            handleChange={handleChange}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-4">
        <div></div>
        <div></div>
        {/* <div className="w-100"> */}
        <button
          className="mx-1 items-center justify-center w-100 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>
        <button
          className="mx-1 items-center justify-center w-100 px-5 py-2 text-sm tracking-wide  transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600"
          onClick={() => {
            handleRefresh();
          }}
        >
          Refresh
        </button>
        {/* </div> */}
      </div>
    </div>
  );
}

function Carousel2() {
  const imageList = [
    "/banners/1.png",
    "/banners/2.png",
    "/banners/3.png",
    // more image URLs
  ];
  // State to track the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Effect to handle auto-scrolling
  useEffect(() => {
    const interval = setInterval(() => {
      // Move to the next slide or loop around
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 3000); // Scrolls every 3000 milliseconds (3 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [imageList.length]); // Rerun the effect if imageList.length changes

  return (
    <>
      <div className="carousel w-full h-26">
        {imageList.map((imageUrl, index) => (
          <div
            id={`slide${index}`}
            key={index}
            // className="carousel-item relative w-full"
            className={`carousel-item relative w-full ${
              index === currentIndex ? "block" : "hidden"
            }`}
          >
            <img src={imageUrl} className="w-full" alt="" />
            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
              {/* <a
                href={`#slide${index === 0 ? imageList.length - 1 : index - 1}`}
                className="btn btn-circle"
              >
                ❮
              </a>
              <a
                href={`#slide${(index + 1) % imageList.length}`}
                className="btn btn-circle"
              > */}
              <a
                onClick={() =>
                  setCurrentIndex(
                    index === 0 ? imageList.length - 1 : index - 1
                  )
                }
                className="btn btn-circle"
              >
                ❮
              </a>
              <a
                onClick={() => setCurrentIndex((index + 1) % imageList.length)}
                className="btn btn-circle"
              >
                ❯
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function footer() {
  return (
    <>
      <>
        <footer class="bg-white dark:bg-gray-900">
          <div class="container flex flex-col items-center justify-between p-6 mx-auto space-y-4 sm:space-y-0 sm:flex-row">
            <a href="#">
              <img class="w-auto h-7" src="hirewynk_logo.png" alt="" />
            </a>

            <p class="text-sm text-gray-600 dark:text-gray-300">
              © Copyright 2023. All Rights Reserved.
            </p>

            <div class="flex -mx-2"></div>
          </div>
        </footer>
      </>
    </>
  );
}

function testimonials() {
  return (
    <>
      <>
        <section class="bg-white dark:bg-gray-900">
          <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
            <h2 class="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
              Bring your Business to the{" "}
              <span class="text-blue-500">next level.</span>
            </h2>

            <p class="max-w-4xl mt-6 text-center text-gray-500 dark:text-gray-300">
              HireWynk revolutionizes recruitment with cutting-edge tech! Our
              AI-driven platform effortlessly matches top talent with your
              business, ensuring you're always ahead. With HireWynk, attract and
              retain the leaders of tomorrow, today. Power your growth with the
              future of hiring!
            </p>

            <div class="inline-flex w-full mt-6 sm:w-auto">
              <a
                href="#"
                class="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </>
    </>
  );
}
