import React from "react";
import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

import { useContext } from "react";
import MainContext from "../../ContextAPI/MainContext/MainContext";

export default function Filters() {
  const mainContext = useContext(MainContext);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [startExperience, setStartExperience] = useState({
    year: "",
    month: "",
  });
  const [endExperience, setEndExperience] = useState({
    year: "",
    month: "",
  });

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const data = localStorage.getItem("liveLeadsFilter");
    if (data) {
      const filters = JSON.parse(data);
      // Transform the array of strings back into an array of objects
      const locations = filters.selectedLocations?.map((location) => ({
        value: location,
        label: location,
      }));
      const preferred = filters.preferredLocation?.map((location) => ({
        value: location,
        label: location,
      }));
      const current = filters.currentLocation?.map((location) => ({
        value: location,
        label: location,
      }));

      setSelectedLocations(locations || []);
      setPreferredLocation(preferred || []);
      setCurrentLocation(current || []);
      setStartExperience(filters.startExperience || { year: "", month: "" });
      setEndExperience(filters.endExperience || { year: "", month: "" });
    }
  }, []);

  // Function to save data to localStorage
  const saveData = () => {
    // Transform arrays of objects to arrays of strings
    const locations = selectedLocations.map((location) => location.value);
    const preferred = preferredLocation.map((location) => location.value);
    const current = currentLocation.map((location) => location.value);

    const liveLeadsFilter = JSON.stringify({
      selectedLocations: locations,
      preferredLocation: preferred,
      currentLocation: current,
      startExperience,
      endExperience,
    });
    localStorage.setItem("liveLeadsFilter", liveLeadsFilter);
    console.log("Filters Saved: ", liveLeadsFilter);
    // mainContext.setLiveLeadsRerender(!mainContext.liveLeadsRerender);
    window.location.reload();
  };
  return (
    <div>
      {/* Open the modal using document.getElementById('ID').showModal() method */}

      {/* <button
        className="btn"
        onClick={() => document.getElementById("my_modal_1").showModal()}
      >
      </button> */}
      <button
        className="btn"
        onClick={() => document.getElementById("my_modal_1").showModal()}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </div>

        {/* <div className="badge">+99</div> */}
      </button>

      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <div className="px-4" style={{ height: "800px", minWidth: "350px" }}>
            {filtersShow(
              selectedLocations,
              setSelectedLocations,
              preferredLocation,
              setPreferredLocation,
              currentLocation,
              setCurrentLocation,
              selectedRoles,
              setSelectedRoles,
              startExperience,
              setStartExperience,
              endExperience,
              setEndExperience
            )}
          </div>

          <div className="modal-action">
            <form method="dialog">
              <button
                className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                onClick={() => {
                  saveData();
                }}
              >
                Save changes
              </button>
              <button className="mx-1 items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-black transition-colors duration-200 bg-base-200 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-base-300 dark:hover:bg-blue-500 dark:bg-blue-600">
                Cancel
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}
function filtersShow(
  selectedLocations,
  setSelectedLocations,
  preferredLocation,
  setPreferredLocation,
  currentLocation,
  setCurrentLocation,
  selectedRoles,
  setSelectedRoles,
  startExperience,
  setStartExperience,
  endExperience,
  setEndExperience
) {
  const LocationSuggestions = [
    // Metro Cities
    { value: "Mumbai", label: "Mumbai" },
    { value: "Delhi / NCR", label: "Delhi / NCR" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Chennai", label: "Chennai" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Pune", label: "Pune" },
    // State Capitals
    { value: "Bhopal", label: "Bhopal" },
    { value: "Patna", label: "Patna" },
    { value: "Panaji", label: "Panaji" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Dispur", label: "Dispur" },
    { value: "Shimla", label: "Shimla" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Itanagar", label: "Itanagar" },
    { value: "Raipur", label: "Raipur" },
    { value: "Shillong", label: "Shillong" },
    { value: "Aizawl", label: "Aizawl" },
    { value: "Kohima", label: "Kohima" },
    { value: "Imphal", label: "Imphal" },
    { value: "Agartala", label: "Agartala" },
    { value: "Lucknow", label: "Lucknow" },
    // Union Territory Capitals
    { value: "Port Blair", label: "Port Blair" },
    { value: "Daman", label: "Daman" },
    { value: "Diu", label: "Diu" },
    { value: "Silvassa", label: "Silvassa" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Leh", label: "Leh" },
    // Popular Cities
    { value: "Varanasi", label: "Varanasi" },
    { value: "Agra", label: "Agra" },
    // Jaipur is duplicated, skipping the second one
    { value: "Udaipur", label: "Udaipur" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Kochi", label: "Kochi" },
    { value: "Mysore", label: "Mysore" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Surat", label: "Surat" },
    { value: "Indore", label: "Indore" },
    { value: "Nagpur", label: "Nagpur" },
    // Lucknow is duplicated, skipping the second one
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Madurai", label: "Madurai" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Nashik", label: "Nashik" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Meerut", label: "Meerut" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Jabalpur", label: "Jabalpur" },
    // Srinagar is duplicated, skipping the second one
    { value: "Thane", label: "Thane" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Amravati", label: "Amravati" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Noida", label: "Noida" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Gurgaon", label: "Gurgaon" },
  ];
  const experienceYears = [
    "Fresher",
    "0 Year(s)",
    "1 Year(s)",
    "2 Year(s)",
    "3 Year(s)",
    "4 Year(s)",
    "5 Year(s)",
    "6 Year(s)",
    "7 Year(s)",
    "8 Year(s)",
    "9 Year(s)",
    "10 Year(s)",
    // Add more years as needed...
  ];

  const experienceMonths = [
    "0 Month(s)",
    "1 Month(s)",
    "2 Month(s)",
    "3 Month(s)",
    "4 Month(s)",
    "5 Month(s)",
    "6 Month(s)",
    "7 Month(s)",
    "8 Month(s)",
    "9 Month(s)",
    "10 Month(s)",
    "11 Month(s)",
    // Add more months as needed...
  ];
  const RoleSuggestions = [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Accountant", label: "Accountant" },
    { value: "Sales Representative", label: "Sales Representative" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    {
      value: "Quality Assurance Engineer",
      label: "Quality Assurance Engineer",
    },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Customer Success Manager", label: "Customer Success Manager" },
    { value: "Administrative Assistant", label: "Administrative Assistant" },
    { value: "Software Developer", label: "Software Developer" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
    { value: "Operations Coordinator", label: "Operations Coordinator" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "UI Designer", label: "UI Designer" },
    { value: "Recruiter", label: "Recruiter" },
    {
      value: "Customer Experience Manager",
      label: "Customer Experience Manager",
    },
    { value: "Financial Controller", label: "Financial Controller" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Account Executive", label: "Account Executive" },
    { value: "Content Manager", label: "Content Manager" },
    { value: "UX Researcher", label: "UX Researcher" },
    { value: "Legal Assistant", label: "Legal Assistant" },
    { value: "Sales Manager", label: "Sales Manager" },
    {
      value: "Customer Support Specialist",
      label: "Customer Support Specialist",
    },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Brand Manager", label: "Brand Manager" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "Accounting Manager", label: "Accounting Manager" },
    { value: "Software Tester", label: "Software Tester" },
    { value: "UX Designer", label: "UX Designer" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "Financial Planner", label: "Financial Planner" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Marketing Coordinator", label: "Marketing Coordinator" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Office Manager", label: "Office Manager" },
    { value: "Customer Service Manager", label: "Customer Service Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Executive Assistant", label: "Executive Assistant" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Sales Associate", label: "Sales Associate" },
    { value: "Event Coordinator", label: "Event Coordinator" },
    { value: "IT Manager", label: "IT Manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    {
      value: "Customer Relations Manager",
      label: "Customer Relations Manager",
    },
    { value: "Operations Analyst", label: "Operations Analyst" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Web Designer", label: "Web Designer" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    {
      value: "Technical Support Engineer",
      label: "Technical Support Engineer",
    },
    { value: "Content Creator", label: "Content Creator" },
    {
      value: "Business Operations Manager",
      label: "Business Operations Manager",
    },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    {
      value: "Software Development Manager",
      label: "Software Development Manager",
    },
    { value: "Financial Advisor", label: "Financial Advisor" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Project Coordinator", label: "Project Coordinator" },
    { value: "IT Support Specialist", label: "IT Support Specialist" },
    { value: "Digital Content Manager", label: "Digital Content Manager" },
    { value: "Sales Coordinator", label: "Sales Coordinator" },
    {
      value: "Human Resources Coordinator",
      label: "Human Resources Coordinator",
    },
    { value: "Network Technician", label: "Network Technician" },
    { value: "Marketing Assistant", label: "Marketing Assistant" },
    { value: "Product Marketing Manager", label: "Product Marketing Manager" },
    { value: "Data Entry Clerk", label: "Data Entry Clerk" },
    { value: "Content Writer", label: "Content Writer" },
    { value: "Operations Assistant", label: "Operations Assistant" },
    { value: "IT Analyst", label: "IT Analyst" },
    { value: "Social Media Coordinator", label: "Social Media Coordinator" },
    { value: "Business Systems Analyst", label: "Business Systems Analyst" },
    { value: "Account Manager", label: "Account Manager" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "UX/UI Developer", label: "UX/UI Developer" },
    { value: "IT Coordinator", label: "IT Coordinator" },
    { value: "Customer Support Engineer", label: "Customer Support Engineer" },
    { value: "Product Analyst", label: "Product Analyst" },
    { value: "Marketing Analyst", label: "Marketing Analyst" },
    { value: "Content Marketing Manager", label: "Content Marketing Manager" },
    {
      value: "Business Intelligence Analyst",
      label: "Business Intelligence Analyst",
    },
    { value: "Finance Manager", label: "Finance Manager" },
    { value: "Database Analyst", label: "Database Analyst" },
    { value: "Technical Project Manager", label: "Technical Project Manager" },
    {
      value: "Customer Success Specialist",
      label: "Customer Success Specialist",
    },
    { value: "Quality Control Inspector", label: "Quality Control Inspector" },
    { value: "Web Content Manager", label: "Web Content Manager" },
    { value: "Human Resources Assistant", label: "Human Resources Assistant" },
    { value: "Systems Architect", label: "Systems Architect" },
    {
      value: "Software Implementation Specialist",
      label: "Software Implementation Specialist",
    },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Technical Support Analyst", label: "Technical Support Analyst" },
    { value: "Marketing Specialist", label: "Marketing Specialist" },
    { value: "Customer Support Manager", label: "Customer Support Manager" },
  ];
  return (
    <div style={{}}>
      <h2 className="font-bold text-lg text-left">Filter Properties</h2>
      <p className="text-left font-light">
        This filter helps you find leads that match your location preferences.
        You can set preferred locations and the leads will only show up if they
        are from these places or are interested in them. It makes sure that the
        leads you see are exactly where you want them to be, making it easier
        for you to find the right opportunities.
      </p>
      <div className="divider"></div>
      <div>
        <div className="text-left">
          {" "}
          <label
            className="text-gray-700 dark:text-gray-200 text-left w-100"
            for="passwordConfirmation"
          >
            Target Locations
          </label>
        </div>

        <MultiSelect
          className="py-2 mt-2 text-left"
          options={LocationSuggestions}
          value={selectedLocations}
          onChange={setSelectedLocations}
          labelledBy="Select"
          style={{ zindex: "100" }}
        />
      </div>
      <div>
        <div className="text-left">
          {" "}
          <label
            className="text-gray-700 dark:text-gray-200 text-left w-100"
            for="passwordConfirmation"
          >
            Current Locations
          </label>
        </div>

        <MultiSelect
          className="py-2 mt-2 text-left"
          options={LocationSuggestions}
          value={currentLocation}
          onChange={setCurrentLocation}
          labelledBy="Select"
          style={{ zindex: "100" }}
        />
      </div>
      <div>
        <div className="text-left">
          {" "}
          <label
            className="text-gray-700 dark:text-gray-200 text-left w-100"
            for="passwordConfirmation"
          >
            Preferred Locations
          </label>
        </div>

        <MultiSelect
          className="py-2 mt-2 text-left"
          options={LocationSuggestions}
          value={preferredLocation}
          onChange={setPreferredLocation}
          labelledBy="Select"
          style={{ zindex: "100" }}
        />
      </div>

      <div>
        <div className="text-left">
          {" "}
          <label
            className="text-gray-700 dark:text-gray-200 text-left w-100"
            for="passwordConfirmation"
          >
            Role / Designation
          </label>
        </div>

        <MultiSelect
          className="py-2 mt-2 text-left"
          options={RoleSuggestions}
          value={selectedRoles}
          onChange={setSelectedRoles}
          labelledBy="Select"
          style={{ zindex: "100" }}
        />
      </div>
      <div>
        <div>
          <div className="text-left mt-2">
            <label className="text-gray-700 dark:text-gray-200" for="text">
              End From Experience
            </label>
          </div>

          {/* totalExperience, setTotalExperience */}
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={startExperience.year}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setStartExperience({
                  ...startExperience,
                  year: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Years
              </option>
              {experienceYears.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={startExperience.month}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setStartExperience({
                  ...startExperience,
                  month: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Months
              </option>
              {experienceMonths.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* <input
            id="password"
            value={candidateDetail.totalExperience}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
        </div>
      </div>
      <div>
        <div>
          <div className="text-left mt-2">
            <label className="text-gray-700 dark:text-gray-200" for="text">
              Start From Experience
            </label>
          </div>

          {/* totalExperience, setTotalExperience */}
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={endExperience.year}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setEndExperience({
                  ...endExperience,
                  year: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Years
              </option>
              {experienceYears.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              name="currentLocation" // Ensure this matches the state key
              className="select select-bordered w-full max-w-xs"
              value={endExperience.month}
              // onChange={handleChange} // Use the generic handleChange for all form elements
              onChange={(event) => {
                // Update the year in totalExperience while keeping the month unchanged
                setEndExperience({
                  ...endExperience,
                  month: event.target.value,
                });
              }}
              defaultValue=""
              aria-label="Application Status"
            >
              <option disabled value="">
                Months
              </option>
              {experienceMonths.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* <input
            id="password"
            value={candidateDetail.totalExperience}
            type="text"
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
          /> */}
        </div>
      </div>
    </div>
  );
}
