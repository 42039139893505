import React, { useEffect } from "react";

// Import Component
import Alert from "./Alert";
import ErrorAlert from "./SystemAlerts/ErrorAlert";
import SuccessAlert from "./SystemAlerts/SuccessAlert";
import WarningAlert from "./SystemAlerts/WarningAlert";
import InfoAlert from "./SystemAlerts/InfoAlert";
import SystemError from "./SystemAlerts/SystemError";
// Import Context API
import { useContext } from "react";
import SystemContext from "../ContextAPI/SystemContext/SystemContext";

export default function SystemAlertWrapper({ children }) {
  const systemContext = useContext(SystemContext);
  useEffect(() => {
    console.log("System Alert: ", systemContext.systemErrorAlertValue);
  }, []);

  return (
    <>
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <div
          style={{
            position: "fixed", // Changed from 'absolute' to 'fixed'
            bottom: 30,
            left: 20,
            zIndex: 1000,
          }}
        >
          {systemContext.systemSuccessAlertValue && (
            <SuccessAlert
              successMessage={systemContext.systemSuccessAlertValue}
              setSuccessMessage={systemContext.setSystemSuccessAlertValue}
            />
          )}
          {systemContext.systemWarningAlertValue && (
            <WarningAlert
              warningMessage={systemContext.systemWarningAlertValue}
              setWarningMessage={systemContext.setSystemWarningAlertValue}
            />
          )}
          {systemContext.systemInfoAlertValue && (
            <InfoAlert
              infoMessage={systemContext.systemInfoAlertValue}
              setInfoMessage={systemContext.setSystemInfoAlertValue}
            />
          )}
          {systemContext.systemErrorAlertValue && (
            <ErrorAlert
              errorMessage={systemContext.systemErrorAlertValue}
              setErrorMessage={systemContext.setSystemErrorAlertValue}
            />
          )}
          {/* {systemContext.systemErrorAlertValue && (
            <SystemError
              errorMessage={systemContext.systemErrorAlertValue}
              setErrorMessage={systemContext.setSystemErrorAlertValue}
            />
          )} */}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
}
